import {
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  Image,
  SelectField,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { useState, useEffect, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import ModalComponent from "./ModalComponent";
import PromoCodeForm from "./PromoCodeForm";
import { PromoClass, PromoCode, Project } from "../models";
import { DataStore } from "aws-amplify";
import PzTooltip from "./PzTooltip";
import { PzPrimeContext } from "../Context";

const PromoCodeFormView = () => {
  const navig = useNavigate();
  const { colorMode } = useContext(PzPrimeContext);
  const [isPromoFormVisible, setIsPromoFormVisible] = useState(false);
  const [mode, setMode] = useState("");
  const [promoCodeObject, setPromoCodeObject] = useState({});
  const [promoCodeList, setPromoCodeList] = useState([]);

  useEffect(() => {
    DataStore.observeQuery(PromoCode).subscribe(({ items }) => {
      // items.sort(function (a, b) {
      //   return b.uses - a.uses;
      // });
      let activeCode = [];
      let inActiveCode = [];

      for (let item of items) {
        if (
          item.disablePromoCode ||
          (item.applyExpirationDate &&
            !(new Date(item.expirationDate) - new Date() > 0))
        ) {
          inActiveCode.push(item);
        } else {
          activeCode.push(item);
        }
      }

      inActiveCode.sort(function (a, b) {
        return b.uses - a.uses;
      });
      activeCode.sort(function (a, b) {
        return b.uses - a.uses;
      });

      activeCode.push(...inActiveCode);
      setPromoCodeList(activeCode);
    });
  }, []);

  function openForm(modeType, promoCode) {
    setMode(modeType);
    if (modeType === "create") {
      setPromoCodeObject({
        code: "",
        requirements: {
          requireSignin: false,
          minimumSubtotal: 0,
        },
        promoClass: PromoClass.PERCENTAGE_DISCOUNT,
        discount: 0,
        disablePromoCode: false,
        applyExpirationDate: false,
        expirationDate: null,
      });
    } else {
      setPromoCodeObject(promoCode);
    }
    setIsPromoFormVisible(true);
  }

  async function calculatePromoUses() {
    const projects = await DataStore.query(Project);
    console.log(projects);
    let promoCodeUses = {};
    for (const project of projects) {
      if (
        project.priceSheet.promoDiscounts &&
        project.priceSheet.promoDiscounts.length > 0
      ) {
        if (
          promoCodeUses.hasOwnProperty(
            project.priceSheet.promoDiscounts[0].code
          )
        ) {
          promoCodeUses[project.priceSheet.promoDiscounts[0].code]++;
        } else {
          promoCodeUses[project.priceSheet.promoDiscounts[0].code] = 1;
        }
      }
    }
    for (const promoCode of promoCodeList) {
      if (
        promoCodeUses.hasOwnProperty(promoCode.code) &&
        promoCodeUses[promoCode.code] > promoCode.uses
      ) {
        await DataStore.save(
          PromoCode.copyOf(promoCode, (promoCodeCopy) => {
            promoCodeCopy.uses = promoCodeUses[promoCode.code];
          })
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
    }
  }

  const customCss = `
  .outline{
    outline: 1px solid rgba(191, 191, 191, 0.80);
    z-index:1;
  }
  .iconHover:hover{
    background:lightblue;
  }
  .hover:hover{
    -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    transition: 0.1s ease-in;
  }
    `;

  return (
    <Flex direction={"column"}>
      <style>{customCss}</style>
      {!isPromoFormVisible && (
        <Flex
          width={"100%"}
          alignItems={"flex-start"}
          wrap={"wrap"}
          className="promoTempLayer"
        >
          <Flex
            className="outline"
            padding="16px 70px 10px 34px"
            alignItems="center"
            boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
            width={"100%"}
            gap={"0"}
            position={"sticky"}
            top={"190px"}
            backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
          >
            <Button
              variation="link"
              textAlign={"center"}
              padding={"10px 25px 10px 5px"}
              margin={"0px 15px"}
              onClick={() => navig("/")}
            >
              <Icon
                ariaLabel="Close"
                fontSize={"25px"}
                pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
              />
            </Button>
            <Heading margin={"5px"} padding={"0"} level={4}>
              Promo Code Library
            </Heading>
            <PzTooltip
              place={"bottom"}
              text="Kenny, DON'T CLICK THIS!"
              children={
                <Button
                  marginLeft={"20px"}
                  onClick={() => calculatePromoUses()}
                >
                  Initialize Promo Counters
                </Button>
              }
            />
          </Flex>
          <Flex wrap={"wrap"} padding={"0 20px"} gap={"0px"}>
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className="cursor hover"
              padding={"10px"}
              maxWidth={"320px"}
              minWidth={"280px"}
              position={"relative"}
              onClick={() => {
                openForm("create");
              }}
              border={"1px solid #c5c5c5"}
              borderRadius={"10px"}
              margin={"10px"}
            >
              <Image
                className="cursor pointerEvents"
                alt="Plus Button"
                src={require("../Assets/addBtn.png")}
                height="150px"
                aspectRatio={"1/1"}
                marginTop={"10px"}
              />
              <Heading
                // level={3}
                fontSize={"25px"}
                //   paddingLeft={"5px"}
                margin={"10px"}
                color={"#aeb3b7"}
                textAlign="center"
              >
                Add Promo Code
              </Heading>
            </Flex>
            {promoCodeList.map((promoCode, index) => (
              <Flex
                key={index}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                className="cursor hover"
                padding={"15px"}
                maxWidth={"320px"}
                minWidth={"280px"}
                position={"relative"}
                onClick={() => {
                  openForm("edit", promoCode);
                }}
                border={
                  promoCode.disablePromoCode ||
                  (promoCode.applyExpirationDate &&
                    !(new Date(promoCode.expirationDate) - new Date() > 0))
                    ? "1px solid #c5c5c5"
                    : "5px solid #319f42"
                }
                borderRadius={"10px"}
                margin={"10px"}
              >
                <View
                  backgroundColor={"#fff"}
                  border={"1px solid #c5c5c5"}
                  borderRadius={"10px"}
                  overflow={"hidden"}
                  className="pointerEvents"
                  width={"100%"}
                  height={"180px"}
                >
                  <LazyLoadImage
                    src={require("../Assets/permitZIP.png")}
                    height="100%"
                    width="100%"
                    className="pointerEvents"
                    effect="blur"
                    style={{
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </View>
                <PzTooltip
                  text={promoCode.code}
                  place={"right"}
                  children={
                    <Heading
                      fontSize={"22px"}
                      margin={"10px"}
                      color={"#aeb3b7"}
                      textAlign="center"
                    >
                      {promoCode.code.length < 11
                        ? promoCode.code
                        : promoCode.code.slice(0, 10) + "..."}
                    </Heading>
                  }
                />
                <Text fontSize={"120%"} fontWeight={"bold"}>
                  {promoCode?.uses ? promoCode.uses : 0}{" "}
                  {promoCode?.uses == 1 ? "use" : "uses"}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}

      {isPromoFormVisible && (
        <PromoCodeForm
          setIsPromoFormVisible={setIsPromoFormVisible}
          promoCodeObject={promoCodeObject}
          setPromoCodeObject={setPromoCodeObject}
          mode={mode}
        />
      )}
      {/* <View className="invisibleBlockPC"></View> */}
    </Flex>
  );
};

export default PromoCodeFormView;
