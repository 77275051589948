import {
  Flex,
  Heading,
  Icon,
  SwitchField,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { useContext } from "react";
import { PzPrimeContext } from "../../Context/ProposalContext";
import "./ProposalDataForm.css";

const ProposalDataForm = ({ tempProposalData, setTempProposalData }) => {
  const { updateProposalData } = useContext(PzPrimeContext);

  const removedKeys = ["path", "totalSF"];

  const editVariableCss = `
    .inputFlex{
    align-items:flex-start;
    gap:"20px";
    }
    .badgeCloseIcon{
    transition:0.5s ease;
    cursor:pointer;
    background-color:#c5c5c5;
    }
    .badgeCloseIcon:hover{
    background-color:lightblue;
    }
    .amplify-switch-label {
    padding: 0;
    padding-right:15px;
    cursor: pointer;
    }
    .cursor{
    cursor:pointer;
    z-index:9;
    }
    .onhover{
    background:#c5c5c5;
    padding:2px
    }
    .icon{
    background:#fff;
    padding:2px
    }
    .onhover:hover{
    background:lightblue;
    transition:0.6s ease;
    }
    .invisibleBlock:after {
    content: "";
    display: block;
    height: 100px;
    width: 100%;
    }
    #listIndent ul>li{
    margin-left:25px;
    }
    `;

  function simpleNode(key, value) {
    return (
      <Flex direction={"column"} className="inputFlex">
        <Flex alignItems={"center"}>
          <Heading>[{key}]</Heading>
          {value.dataType === "boolean" ? (
            <SwitchField
              name={key}
              onChange={(e) => {
                editObj(key, e.target.checked);
              }}
              isChecked={value.value}
            />
          ) : value.hasOwnProperty("value") ? (
            <Flex alignItems={"center"} gap={"10px"}>
              <TextField
                name={key}
                type={value.dataType}
                value={value.value}
                onChange={(e) => {
                  editObj(key, e.target.value);
                }}
                onWheel={(e) => e.target.blur()}
                onFocus={(e) => e.target.select()}
              />
              <Icon
                borderRadius={"50%"}
                padding={"2px"}
                marginTop={"5px"}
                onClick={() => {
                  let tempObj = JSON.parse(JSON.stringify(tempProposalData));
                  delete tempObj[key]["value"];
                  setTempProposalData(updateProposalData(tempObj));
                }}
                fontSize={"25px"}
                id="deleteIcon"
              >
                <svg className="cursor" fill="red" viewBox="0 0 24 24">
                  <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z" />
                </svg>
              </Icon>
            </Flex>
          ) : (
            <Icon
              className="cursor onhover"
              borderRadius={"50%"}
              padding={"2px"}
              onClick={() => {
                let tempObj = JSON.parse(JSON.stringify(tempProposalData));
                tempObj[key]["value"] = null;
                setTempProposalData(tempObj);
              }}
              fontSize={"20px"}
              id="editIcon"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 24 24"
                fill="#545352"
                xmlns="http://www.w3.org/2000/svg"
                className="onhover"
              >
                <path d="M3 17.2505V21.0005H6.75L17.81 9.94049L14.06 6.19049L3 17.2505ZM20.71 5.63049L18.37 3.29049C18.2775 3.19779 18.1676 3.12424 18.0466 3.07406C17.9257 3.02388 17.796 2.99805 17.665 2.99805C17.534 2.99805 17.4043 3.02388 17.2834 3.07406C17.1624 3.12424 17.0525 3.19779 16.96 3.29049L15.13 5.12049L18.88 8.87049L20.71 7.04049C20.8027 6.94798 20.8762 6.83809 20.9264 6.71712C20.9766 6.59614 21.0024 6.46646 21.0024 6.33549C21.0024 6.20452 20.9766 6.07484 20.9264 5.95387C20.8762 5.83289 20.8027 5.723 20.71 5.63049Z" />
              </svg>
            </Icon>
          )}
        </Flex>
        <span>({value.description})</span>
      </Flex>
    );
  }

  function editObj(key, currentValue) {
    let tempObj = JSON.parse(JSON.stringify(tempProposalData));
    if (tempObj[key].dataType === "boolean")
      tempObj[key]["value"] = currentValue;
    else if (currentValue === "") tempObj[key]["value"] = null;
    else if (!isNaN(currentValue))
      tempObj[key]["value"] = parseFloat(currentValue);
    else tempObj[key]["value"] = currentValue;
    setTempProposalData(updateProposalData(tempObj));
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function calculatedFields(key, calcValue, isValueList, valKey) {
    return (
      <Flex direction={"column"}>
        <Flex gap={"10px"} direction={"column"}>
          <Heading>[{key}]</Heading>
          <span>({calcValue.description})</span>
        </Flex>
        <Heading fontWeight={"500"}>Output (Calculated)</Heading>

        <Flex direction={"column"} marginLeft={"25px"} width={"fit-content"}>
          <Text
            backgroundColor={"#c5c5c5"}
            padding={"25px"}
            borderRadius={"10px"}
            id="listIndent"
            dangerouslySetInnerHTML={{ __html: calcValue.value }}
          ></Text>
        </Flex>
        <Flex direction={"column"} marginLeft={"25px"} width={"fit-content"}>
          {isValueList && renderSpaces(calcValue, valKey)}
        </Flex>
      </Flex>
    );
  }

  function checkDataType(value) {
    let dataTypeExist = false;
    let key = "";
    for (const valKey in value) {
      if (
        value[valKey].hasOwnProperty("dataType") &&
        value[valKey].dataType === "valueList"
      ) {
        dataTypeExist = true;
        key = valKey;
        break;
      }
    }
    return [key, dataTypeExist];
  }

  function addObj(spaceValue, valKey) {
    let rootKey = "";
    let tempObj = JSON.parse(JSON.stringify(tempProposalData));
    if (spaceValue.hasOwnProperty("path")) {
      let pathList = spaceValue.path.split("-");
      rootKey = pathList[0];
    } else {
      for (const key in tempProposalData) {
        if (
          JSON.stringify(spaceValue) === JSON.stringify(tempProposalData[key])
        ) {
          rootKey = key;
          break;
        }
      }
    }
    if (rootKey === "projectSpaces") {
      if (valKey === "spaces") {
        let spaceObjs = Object.keys(tempObj[rootKey][valKey]).filter(
          (key) => !isNaN(key)
        );
        let spaceObjLength = 0;
        if (spaceObjs.length !== 0) {
          spaceObjLength = Math.max(...spaceObjs) + 1;
        }

        if (tempObj[rootKey][valKey].default.hasOwnProperty("spaceType"))
          tempObj[rootKey][valKey][spaceObjLength] = {
            spaceType: tempObj[rootKey][valKey].default.spaceType,
            path: `projectSpaces-spaces-${spaceObjLength}`,
            areas: {
              dataType: "valueList",
              0: {
                name: tempObj[rootKey][valKey].default.areas.default.name,
                SF: tempObj[rootKey][valKey].default.areas.default.SF,
                path: `projectSpaces-spaces-${spaceObjLength}-areas-0`,
              },
            },
          };
        // console.log(tempObj[rootKey][valKey])
      } else if (valKey === "areas") {
        let currentObj = tempObj;
        for (const key of spaceValue.path.split("-")) {
          currentObj = currentObj[key];
        }
        let areaObjs = Object.keys(currentObj.areas).filter(
          (key) => !isNaN(key)
        );
        let areaObjLength = 0;
        if (areaObjs.length !== 0) {
          areaObjLength = Math.max(...areaObjs) + 1;
        }
        currentObj.areas[areaObjLength] = {
          name: tempObj[rootKey].spaces.default.areas.default.name,
          SF: tempObj[rootKey].spaces.default.areas.default.SF,
          path: spaceValue.path + `-areas-${areaObjLength}`,
        };
        // console.log(currentObj)
      }
    }

    setTempProposalData(updateProposalData(tempObj));
  }

  function renderSpaces(spaceValue, valKey) {
    return (
      <Flex gap={"10px"} direction={"column"} id="projectSpaces">
        <Flex direction={"column"} gap={"0px"}>
          <Text fontSize={"18px"}>{capitalizeFirstLetter(valKey)}:</Text>
          <Flex marginLeft={"80px"} direction={"column"}>
            <ul>
              {Object.entries(spaceValue[valKey]).map(
                ([spaceKey, spaceVal], i) => {
                  if (!isNaN(spaceKey)) {
                    const [objKey, isObjValueList] = checkDataType(spaceVal);
                    return (
                      <Flex
                        direction={"column"}
                        position={"relative"}
                        gap={"0px"}
                        className="nodeList"
                      >
                        <Icon
                          borderRadius={"50%"}
                          className="cursor icon"
                          padding={"3px"}
                          top={"3px"}
                          fontSize={"22px"}
                          id="deleteIcon"
                          position={"absolute"}
                          left={"-50px"}
                          onClick={() => {
                            console.log("spaceVal --> ", spaceVal);
                            let tempObj = JSON.parse(
                              JSON.stringify(tempProposalData)
                            );
                            let currentObj = tempObj;
                            let pathList = spaceVal.path.split("-");
                            for (const key of pathList.slice(
                              0,
                              pathList.length - 1
                            )) {
                              currentObj = currentObj[key];
                            }
                            console.log(
                              "to be deleted ",
                              currentObj[pathList[pathList.length - 1]]
                            );
                            delete currentObj[pathList[pathList.length - 1]];
                            console.log(tempObj);
                            setTempProposalData(updateProposalData(tempObj));
                          }}
                        >
                          <svg fill="red" viewBox="0 0 24 24">
                            <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z" />
                          </svg>
                        </Icon>
                        {Object.entries(spaceVal).map(([node, nodeVal], i) => {
                          if (node !== objKey) {
                            if (!removedKeys.includes(node))
                              return (
                                <>{renderLists(node, nodeVal, spaceVal)}</>
                              );
                          } else {
                            return renderSpaces(spaceVal, node);
                          }
                        })}
                      </Flex>
                    );
                  }
                }
              )}
            </ul>
          </Flex>
          <Icon
            padding={"3px"}
            fontSize={"35px"}
            id="addIcon"
            className="cursor"
            width={"fit-content"}
            marginLeft={"25px"}
            onClick={(e) => {
              addObj(spaceValue, valKey);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path
                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M37,26H26v11h-2V26H13v-2h11V13h2v11h11V26z"
                fill="#c5c5c5"
              />
            </svg>
          </Icon>
        </Flex>
      </Flex>
    );
  }

  function editRenderObj(e, node, value, spaceVal) {
    let tempObj = JSON.parse(JSON.stringify(tempProposalData));
    let currentObj = tempObj;
    for (const key of spaceVal.path.split("-")) {
      currentObj = currentObj[key];
    }
    if (e.target.value === "") {
      if (typeof value === "number") {
        currentObj[node] = 0;
      } else {
        currentObj[node] = "";
      }
    } else if (typeof value === "number") {
      currentObj[node] = parseFloat(e.target.value);
    } else {
      currentObj[node] = e.target.value;
    }

    setTempProposalData(updateProposalData(tempObj));
  }

  function renderLists(node, value, spaceVal) {
    return (
      <li>
        <Flex alignItems={"center"} gap={"5px"}>
          <Text fontSize={"18px"} display={"inline"}>
            {capitalizeFirstLetter(node)}:
          </Text>{" "}
          <input
            value={value}
            onChange={(e) => editRenderObj(e, node, value, spaceVal)}
            className="inputBox"
            type={typeof value === "number" ? "number" : "text"}
            onWheel={(e) => e.target.blur()}
            onFocus={(e) => e.target.select()}
          />
        </Flex>
      </li>
    );
  }

  return (
    <>
      <style>{editVariableCss}</style>

      <Flex direction={"column"} width={"100%"} paddingLeft={"15px"}>
        <Heading level={"3"} marginBottom={"20px"}>
          Available variables:
        </Heading>
        {Object.entries(tempProposalData).map(([key, value], i) => {
          if (
            !value.hasOwnProperty("hideReference") &&
            !value.hasOwnProperty("calculated")
          )
            return simpleNode(key, value);
          if (
            !value.hasOwnProperty("hideReference") &&
            value.hasOwnProperty("calculated")
          ) {
            let [valKey, isValueList] = checkDataType(value);
            return calculatedFields(key, value, isValueList, valKey);
          }
        })}
        <View className="invisibleBlock"></View>
      </Flex>
    </>
  );
};

export default ProposalDataForm;
