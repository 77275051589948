import {
  Flex,
  View,
  Heading,
  Image,
  Button,
  Icon,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  DisciplineBasePrice,
  BasePrice,
  PriceClass,
  PriceSheet,
  PricingModel,
} from "../models";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SpaceTemplateForm from "./SpaceTemplateForm";
import { useNavigate } from "react-router-dom";

const SpaceTemplateFormView = () => {
  const navig = useNavigate();
  const { spaceImages, user, setSpaceImages, ProjectCalculator, colorMode } =
    useContext(PzPrimeContext);
  const [spaceTemplates, setSpaceTemplates] = useState([]);
  const [isSpaceFormVisible, setIsSpaceFormVisible] = useState(false);
  const [isInstantQuoteSpace, setIsInstantQuoteSpace] = useState(false);
  const [isSumOfAreaAssignments, setIsSumOfAreaAssignments] =
    useState(false);

  const tradeConversion = {
    mech: "MECH",
    elec: "ELEC",
    plum: "PLUMB",
  };

  const [spaceTemplateList, setSpaceTemplateList] = useState([
    {
      areaTitle: "",
      pricePercent: {
        mech: 100,
        elec: 100,
        plum: 100,
      },
      priceSF: {
        mech: 0,
        elec: 0,
        plum: 0,
      },
    },
  ]);
  const [tempImage, setTempImage] = useState();
  const [spaceTemplate, setSpaceTemplate] = useState();
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [mode, setMode] = useState("create");
  const [setUpFee, setSetUpFee] = useState({
    mech: [0, 0],
    elec: [0, 0],
    plum: [0, 0],
    total: [0, 0],
  });
  const [projectSetUpFee, setProjectSetUpFee] = useState({
    mech: 0,
    elec: 0,
    plum: 0,
    total: 0,
  });
  const [disciplineCostPerSF, setDisciplineCostPerSF] = useState({
    mech: 0,
    elec: 0,
    plum: 0,
  });

  const [calculatedSpace, setCalculatedSpace] = useState({});

  async function getDisciplineCost() {
    const disciplineRecords = await DataStore.query(
      BasePrice,
      (basePriceType) => basePriceType.priceClass.eq(PriceClass.DISCIPLINE)
    );
    projectSetUpFee["total"] = 0;
    for (let trade in disciplineCostPerSF) {
      let tradeRecord = disciplineRecords.filter((dis) => dis.name === trade);
      disciplineCostPerSF[trade] = parseFloat(tradeRecord[0]["costPerSF"]);
      projectSetUpFee[trade] = parseFloat(tradeRecord[0]["setUpFee"]);
      projectSetUpFee["total"] += parseFloat(tradeRecord[0]["setUpFee"]);
    }
    setDisciplineCostPerSF(disciplineCostPerSF);
    setProjectSetUpFee(projectSetUpFee);
  }

  async function getImages(items) {
    let totalSpacesImage = {};
    for (const space of items) {
      if (space.imageKey)
        totalSpacesImage[space.id] = {
          imageURL: await Storage.get(`assets/spaceImages/${space.imageKey}`, {
            download: true,
          }).then((imageURL) => URL.createObjectURL(imageURL.Body)),
        };
    }
    setSpaceImages(totalSpacesImage);
  }

  useEffect(() => {
    DataStore.observeQuery(SpaceTemplate).subscribe(({ items }) => {
      setSpaceTemplates(items);
      getImages(items);
    });
    getDisciplineCost();
  }, [user]);

  const customCss = `
    .outline{
      outline: 1px solid rgba(191, 191, 191, 0.80);
      z-index:1
    }
    .iconHover:hover{
      background:lightblue;
    }
    .hover:hover{
      -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      transition: 0.1s ease-in;
    }
      .invisibleBlockST:after {
        content: "";
        display: block;
        height: 60px;
        width: 100%;
      }
      `;

  function getCost(trade, cost) {
    return parseFloat(
      ((parseFloat(cost) * disciplineCostPerSF[trade]) / 100).toFixed(3)
    );
  }

  async function showForm(spaceTemplate) {
    await getDisciplineCost();
    let areaFields = [];
    if (!spaceTemplate) {
      areaFields = [
        {
          areaTitle: "area-1",
          pricePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
          },
          priceSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
          },
          pricingModel: "RELATIVE_LINEAR",
          initialRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
          },
          initialRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
          },
          fullyDiscountedRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
          },
          fullyDiscountedRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
          },
          fullDiscount: {
            mech: "",
            elec: "",
            plum: "",
          },
          totalCheck: {
            mech: 0,
            elec: 0,
            plum: 0,
          },
          showTheElementLinear: "both",
          showTheElementComp: "both",
          instantQuoteWeight: 0,
        },
      ];
      setSpaceTemplateList(areaFields);
      setSelectedMarkets([]);
      setTemplateName("");
      setMode("create");
      setSpaceTemplate({});
      setSetUpFee({
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        total: [0, 0],
      });
      setTempImage(null);
      setIsInstantQuoteSpace(false);
    } else {
      setSpaceTemplate(spaceTemplate);

      for (const area of spaceTemplate.areas) {
        if (area.priceSheet) {
          if (area.priceSheet.pricingModel === "RELATIVE_LINEAR")
            areaFields.push({
              areaTitle: area.areaTitle,
              pricePercent: {
                mech: area.priceSheet.mech[0],
                elec: area.priceSheet.elec[0],
                plum: area.priceSheet.plum[0],
              },
              priceSF: {
                mech: getCost("mech", area.priceSheet.mech[0]),
                elec: getCost("elec", area.priceSheet.elec[0]),
                plum: getCost("plum", area.priceSheet.plum[0]),
              },
              pricingModel: "RELATIVE_LINEAR",
              initialRatePercent: {
                mech: 100,
                elec: 100,
                plum: 100,
              },
              initialRateSF: {
                mech: getCost("mech", 100),
                elec: getCost("elec", 100),
                plum: getCost("plum", 100),
              },
              fullyDiscountedRatePercent: {
                mech: 100,
                elec: 100,
                plum: 100,
              },
              fullyDiscountedRateSF: {
                mech: getCost("mech", 100),
                elec: getCost("elec", 100),
                plum: getCost("plum", 100),
              },
              fullDiscount: {
                mech: "",
                elec: "",
                plum: "",
              },
              totalCheck: {
                mech: 0,
                elec: 0,
                plum: 0,
              },
              showTheElementLinear: "both",
              showTheElementComp: "both",
              instantQuoteWeight: area.instantQuoteWeight,
            });
          else
            areaFields.push({
              areaTitle: area.areaTitle,
              pricePercent: {
                mech: 100,
                elec: 100,
                plum: 100,
              },
              priceSF: {
                mech: getCost("mech", 100),
                elec: getCost("elec", 100),
                plum: getCost("plum", 100),
              },
              pricingModel: "COMPRESSED",
              initialRatePercent: {
                mech: area.priceSheet.mech[0],
                elec: area.priceSheet.elec[0],
                plum: area.priceSheet.plum[0],
              },
              initialRateSF: {
                mech: getCost("mech", area.priceSheet.mech[0]),
                elec: getCost("elec", area.priceSheet.elec[0]),
                plum: getCost("plum", area.priceSheet.plum[0]),
              },
              fullyDiscountedRatePercent: {
                mech: area.priceSheet.mech[1],
                elec: area.priceSheet.elec[1],
                plum: area.priceSheet.plum[1],
              },
              fullyDiscountedRateSF: {
                mech: getCost("mech", area.priceSheet.mech[1]),
                elec: getCost("elec", area.priceSheet.elec[1]),
                plum: getCost("plum", area.priceSheet.plum[1]),
              },
              fullDiscount: {
                mech: area.priceSheet.mech[2],
                elec: area.priceSheet.elec[2],
                plum: area.priceSheet.plum[2],
              },
              totalCheck: {
                mech: area.priceSheet.mech[3],
                elec: area.priceSheet.elec[3],
                plum: area.priceSheet.plum[3],
              },
              showTheElementLinear: "both",
              showTheElementComp: "both",
              instantQuoteWeight: area.instantQuoteWeight,
            });
        }
      }
      if (areaFields.length === 0)
        areaFields.push({
          areaTitle: "area-1",
          pricePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
          },
          priceSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
          },
          pricingModel: "RELATIVE_LINEAR",
          initialRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
          },
          initialRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
          },
          fullyDiscountedRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
          },
          fullyDiscountedRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
          },
          fullDiscount: {
            mech: "",
            elec: "",
            plum: "",
          },
          totalCheck: {
            mech: 0,
            elec: 0,
            plum: 0,
          },
          showTheElementLinear: "both",
          showTheElementComp: "both",
          instantQuoteWeight: 0,
        });
      if (spaceTemplate.priceSheet) {
        setUpFee["total"] = [0, 0];
        for (const fee in spaceTemplate.priceSheet) {
          if (["mech", "elec", "plum"].includes(fee)) {
            setUpFee[fee] = spaceTemplate.priceSheet[fee];
            setUpFee["total"][0] += spaceTemplate.priceSheet[fee][0]
              ? spaceTemplate.priceSheet[fee][0]
              : 0;
            setUpFee["total"][1] += spaceTemplate.priceSheet[fee][1]
              ? spaceTemplate.priceSheet[fee][1]
              : 0;
          }
        }
        setSetUpFee(setUpFee);
      } else
        setSetUpFee({
          mech: [0, 0],
          elec: [0, 0],
          plum: [0, 0],
          total: [0, 0],
        });
      setSpaceTemplateList(areaFields);
      setSelectedMarkets(spaceTemplate.markets);
      setTemplateName(spaceTemplate.templateName);
      setMode("edit");
      setTempImage(spaceImages[spaceTemplate.id]?.imageURL);
      setIsInstantQuoteSpace(
        spaceTemplate.spaceTemplateInstantQuoteSpaceId ? true : false
      );
    }
    let total = 0;
    for (const area of areaFields) {
      if(!isNaN(area.instantQuoteWeight))
        total += area.instantQuoteWeight;
    }
    console.log(total);
    setIsSumOfAreaAssignments(!(isNaN(total) || total == 0));
    await getSpaceObj(1000, areaFields);
    setIsSpaceFormVisible(true);
  }

  async function getSpaceObj(val, spaceTemplateList) {
    let newSpace = {
      customName: templateName,
      className: templateName,
      areas: [],
      priceSheet: new PriceSheet({
        pricingModel: PricingModel.SPACE_SETUP,
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
      }),
    };
    for (const input of spaceTemplateList) {
      // console.log(input);
      let areaPriceSheet = {};
      if (input.pricingModel === "RELATIVE_LINEAR") {
        areaPriceSheet = {
          pricingModel: PricingModel.RELATIVE_LINEAR,
          mech: input.pricePercent.mech ? [input.pricePercent.mech] : [100],
          elec: input.pricePercent.elec ? [input.pricePercent.elec] : [100],
          plum: input.pricePercent.plum ? [input.pricePercent.plum] : [100],
        };
      } else {
        areaPriceSheet = {
          pricingModel: PricingModel.COMPRESSED,
          mech: [
            input.initialRatePercent.mech ? input.initialRatePercent.mech : 100,
            input.fullyDiscountedRatePercent.mech
              ? input.fullyDiscountedRatePercent.mech
              : 100,
            input.fullDiscount.mech ? input.fullDiscount.mech : 0,
            input.totalCheck.mech ? input.totalCheck.mech : 0,
          ],
          elec: [
            input.initialRatePercent.elec ? input.initialRatePercent.elec : 100,
            input.fullyDiscountedRatePercent.elec
              ? input.fullyDiscountedRatePercent.elec
              : 100,
            input.fullDiscount.elec ? input.fullDiscount.elec : 0,
            input.totalCheck.elec ? input.totalCheck.elec : 0,
          ],
          plum: [
            input.initialRatePercent.plum ? input.initialRatePercent.plum : 100,
            input.fullyDiscountedRatePercent.plum
              ? input.fullyDiscountedRatePercent.plum
              : 100,
            input.fullDiscount.plum ? input.fullDiscount.plum : 0,
            input.totalCheck.plum ? input.totalCheck.plum : 0,
          ],
        };
      }
      let spaceInputField = {
        areaTitle: input.areaTitle,
        instantQuoteWeight: input.instantQuoteWeight,
        area: parseFloat((val * input.instantQuoteWeight) / 100),
        priceSheet: areaPriceSheet,
      };
      newSpace["areas"].push(spaceInputField);
    }
    console.log(newSpace);
    let space = await ProjectCalculator(null, null, null, "", newSpace, [
      "mech",
      "elec",
      "plum",
    ]);
    console.log(space);
    setCalculatedSpace(space);
  }

  return (
    <Flex direction={"column"}>
      <style>{customCss}</style>
      {!isSpaceFormVisible && (
        <Flex width={"100%"} alignItems={"flex-start"} wrap={"wrap"}>
          <Flex
            className="outline"
            padding="10px 70px 10px 34px"
            alignItems="center"
            boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
            width={"100%"}
            gap={"0"}
            position={"sticky"}
            top={"190px"}
            backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
          >
            <Button
              variation="link"
              textAlign={"center"}
              padding={"10px 25px 10px 5px"}
              margin={"0px 15px"}
              onClick={() => navig("/")}
            >
              <Icon
                ariaLabel="Close"
                fontSize={"25px"}
                pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
              />
            </Button>
            <Heading margin={"5px"} padding={"0"} level={4}>
              Space Template Library
            </Heading>
          </Flex>
          <Flex wrap={"wrap"} padding={"0 20px"} gap={"0px"}>
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className="cursor hover"
              padding={"10px"}
              maxWidth={"320px"}
              position={"relative"}
              onClick={() => showForm(null)}
              border={"1px solid #c5c5c5"}
              borderRadius={"10px"}
              margin={"10px"}
            >
              <Image
                className="cursor pointerEvents"
                alt="Plus Button"
                src={require("../Assets/addBtn.png")}
                height="150px"
                aspectRatio={"1/1"}
                marginTop={"10px"}
              />
              <Heading
                level={3}
                paddingLeft={"5px"}
                margin={"10px"}
                color={"#aeb3b7"}
                textAlign="center"
              >
                Add Space Template
              </Heading>
            </Flex>
            {spaceTemplates.map((spaceTemplate, index) => (
              <Flex
                key={index}
                direction={"column"}
                alignItems={"center"}
                className="cursor hover"
                padding={"15px"}
                width={"320px"}
                position={"relative"}
                onClick={() => showForm(spaceTemplate)}
                border={"1px solid #c5c5c5"}
                borderRadius={"10px"}
                margin={"10px"}
              >
                <View
                  border={"1px solid #c5c5c5"}
                  borderRadius={"10px"}
                  overflow={"hidden"}
                  className="pointerEvents"
                  width={"100%"}
                  height={"205px"}
                >
                  <LazyLoadImage
                    src={
                      spaceImages[spaceTemplate.id]
                        ? spaceImages[spaceTemplate.id]?.imageURL
                        : require("../Assets/defaultSpaceImage.jpg")
                    }
                    alt={templateName}
                    height="100%"
                    width="100%"
                    className="pointerEvents"
                    effect="blur"
                    style={{
                      objectFit: "cover",
                      border: "1px solid #c5c5c5",
                      borderRadius: "10px",
                    }}
                  />
                </View>
                <Heading
                  level={4}
                  paddingLeft={"5px"}
                  margin={"10px"}
                  textAlign={"center"}
                  marginBottom={"15px"}
                >
                  {spaceTemplate.templateName}
                </Heading>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}

      {isSpaceFormVisible ? (
        <SpaceTemplateForm
          open={isSpaceFormVisible}
          onClose={() => setIsSpaceFormVisible(false)}
          spaceTemplateList={spaceTemplateList}
          setSpaceTemplateList={setSpaceTemplateList}
          selectedMarkets={selectedMarkets}
          setSelectedMarkets={setSelectedMarkets}
          templateName={templateName}
          setTemplateName={setTemplateName}
          mode={mode}
          spaceTemplate={spaceTemplate}
          setUpFee={setUpFee}
          setSetUpFee={setSetUpFee}
          disciplineCostPerSF={disciplineCostPerSF}
          tempImage={tempImage}
          setTempImage={setTempImage}
          isInstantQuoteSpace={isInstantQuoteSpace}
          setIsInstantQuoteSpace={setIsInstantQuoteSpace}
          calculatedSpace={calculatedSpace}
          setCalculatedSpace={setCalculatedSpace}
          projectSetUpFee={projectSetUpFee}
          isSumOfAreaAssignments={isSumOfAreaAssignments}
          setIsSumOfAreaAssignments={setIsSumOfAreaAssignments}
        />
      ) : null}
    </Flex>
  );
};

export default SpaceTemplateFormView;
