// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ConstructionType = {
  "GROUND_UP": "GROUND_UP",
  "ADAPTIVE_REUSE_CLEAN_SLATE": "ADAPTIVE_REUSE_CLEAN_SLATE",
  "ADAPTIVE_REUSE_RECYCLED_SYSTEMS": "ADAPTIVE_REUSE_RECYCLED_SYSTEMS"
};

const ContractPackageName = {
  "GIST": "GIST",
  "MINIMALIST": "MINIMALIST",
  "COLLABORATOR": "COLLABORATOR",
  "PERFECTIONIST": "PERFECTIONIST",
  "TWO_WEEK": "TWO_WEEK",
  "MINI": "MINI"
};

const TradeSupervision = {
  "MECH": "MECH",
  "ELEC": "ELEC",
  "PLUMB": "PLUMB",
  "GAS": "GAS"
};

const Market = {
  "MULTIFAMILY_MIXED_USE": "MULTIFAMILY_MIXED_USE",
  "OFFICE_RETAIL": "OFFICE_RETAIL",
  "FOOD_BEVERAGE": "FOOD_BEVERAGE",
  "HEALTHCARE": "HEALTHCARE",
  "CORE_SHELL": "CORE_SHELL",
  "WAREHOUSE_STORAGE": "WAREHOUSE_STORAGE",
  "SINGLE_FAMILY_RESIDENTIAL": "SINGLE_FAMILY_RESIDENTIAL",
  "RELIGIOUS_CULTURAL": "RELIGIOUS_CULTURAL",
  "EDUCATION_DAYCARE": "EDUCATION_DAYCARE",
  "FACTORY_INDUSTRIAL_GARAGE": "FACTORY_INDUSTRIAL_GARAGE",
  "THEATERS_EVENT_SPACES": "THEATERS_EVENT_SPACES",
  "OTHER": "OTHER"
};

const DataType = {
  "INTEGER": "INTEGER",
  "CHECKBOX": "CHECKBOX",
  "CHECKBOX_W_INTEGER": "CHECKBOX_W_INTEGER"
};

const AlertLevel = {
  "SUCCESS": "SUCCESS",
  "WARNING": "WARNING",
  "ERROR": "ERROR"
};

const PricingModel = {
  "PROJECT_BASE_FEES": "PROJECT_BASE_FEES",
  "RELATIVE_LINEAR": "RELATIVE_LINEAR",
  "SPACE_SETUP": "SPACE_SETUP",
  "COMPRESSED": "COMPRESSED"
};

const PriceClass = {
  "ADJUSTMENT": "ADJUSTMENT",
  "DISCIPLINE": "DISCIPLINE",
  "TAX": "TAX"
};

const PromoClass = {
  "PERCENTAGE_DISCOUNT": "PERCENTAGE_DISCOUNT",
  "FLAT_DISCOUNT": "FLAT_DISCOUNT"
};

const { PromoCode, Space, DisciplineBasePrice, BasePrice, Proposal, SpaceTemplate, InstantQuoteSpace, CostFlagTemplate, Organization, Project, InstantQuote, SpaceCostFlagRelationships, ProjectLocation, AppliedDiscount, PriceSheet, Area, CostFlag, SpaceInput, BuildingSF, UserDetails, PromoCodeReference, PromoRequirements } = initSchema(schema);

export {
  PromoCode,
  Space,
  DisciplineBasePrice,
  BasePrice,
  Proposal,
  SpaceTemplate,
  InstantQuoteSpace,
  CostFlagTemplate,
  Organization,
  Project,
  InstantQuote,
  SpaceCostFlagRelationships,
  ConstructionType,
  ContractPackageName,
  TradeSupervision,
  Market,
  DataType,
  AlertLevel,
  PricingModel,
  PriceClass,
  PromoClass,
  ProjectLocation,
  AppliedDiscount,
  PriceSheet,
  Area,
  CostFlag,
  SpaceInput,
  BuildingSF,
  UserDetails,
  PromoCodeReference,
  PromoRequirements
};