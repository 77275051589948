import {
  Flex,
  Text,
  Heading,
  Icon,
  TextField,
  Button,
  SelectField,
  Badge,
  Table,
  TableRow,
  TableCell,
  View,
  Card,
  RadioGroupField,
  Radio,
  Image,
  CheckboxField,
  Alert,
  Link,
  SliderField,
} from "@aws-amplify/ui-react";
// import { uploadData } from "aws-amplify/storage";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  CostFlagTemplate,
  SpaceCostFlagRelationships,
  PriceSheet,
  PricingModel,
  InstantQuoteSpace,
} from "../models";
import Swal from "sweetalert2";

const SpaceTemplateForm = ({
  onClose,
  spaceTemplateList,
  setSpaceTemplateList,
  selectedMarkets,
  setSelectedMarkets,
  templateName,
  setTemplateName,
  mode,
  spaceTemplate,
  setUpFee,
  setSetUpFee,
  disciplineCostPerSF,
  tempImage,
  setTempImage,
  isInstantQuoteSpace,
  setIsInstantQuoteSpace,
  calculatedSpace,
  setCalculatedSpace,
  projectSetUpFee,
  isSumOfAreaAssignments,
  setIsSumOfAreaAssignments,
}) => {
  const compressedCalculatorLink =
    process.env.REACT_APP_COMPRESSED_CALCULATOR_LINK;

  const { colorMode, ProjectCalculator, formatNumberUS } =
    useContext(PzPrimeContext);

  const [costFlags, setCostFlags] = useState([]);

  const [flagNames, setFlagNames] = useState([]);

  const [nameError, setNameError] = useState(false);

  const [isAlphaNumeric, setIsAlphaNumeric] = useState(true);

  const [isMultipleSpace, setIsMultipleSpace] = useState(false);

  const [isTrailingSpace, setIsTrailingSpace] = useState(false);

  const [changeInMarket, setChangeInMarket] = useState(false);

  const [changeInSetUpFee, setChangeInSetUpFee] = useState(false);

  const [selectedImage, setSelectedImage] = useState(false);

  const [focusOut, setFocusOut] = useState(false);

  const [isFieldEmpty, setIsFieldEmpty] = useState(false);

  const [includeSpaceSetUpFee, setIncludeSpaceSetUpFee] = useState(true);

  const [includeProjectSetUpFee, setIncludeProjectSetUpFee] = useState(false);

  const [isMechSelected, setIsMechSelected] = useState(true);

  const [isElecSelected, setIsElecSelected] = useState(true);

  const [isPlumSelected, setIsPlumSelected] = useState(true);

  const [spaceSF, setSpaceSF] = useState(1000);

  const [maxSF, setMaxSF] = useState(2000);

  const [saveMaxSF, setSaveMaxSF] = useState(2000);

  async function getFlags() {
    const flags = await DataStore.query(CostFlagTemplate);
    let flagNameList = [];
    for (const flag of flags)
      flagNameList.push({ id: flag.id, label: flag.featureTitle });
    setFlagNames(flagNameList);
    const selectedFlags = await DataStore.query(CostFlagTemplate, (flag) =>
      flag.spaces.spaceTemplateId.eq(spaceTemplate.id)
    );
    let selectedCostFlags = [];
    for (const flag of selectedFlags)
      selectedCostFlags.push({ id: flag.id, name: flag.featureTitle });
    setCostFlags(selectedCostFlags);
  }

  useEffect(() => {
    getFlags();
  }, []);

  useEffect(() => {}, [changeInMarket, changeInSetUpFee]);

  const marketConversion = {
    "Multifamily & Mixed Use": "MULTIFAMILY_MIXED_USE",
    "Office and Retail": "OFFICE_RETAIL",
    "Food and Beverage": "FOOD_BEVERAGE",
    Healthcare: "HEALTHCARE",
    "Core / Shell": "CORE_SHELL",
    "Warehouse / Storage": "WAREHOUSE_STORAGE",
    "Single Family Residential": "SINGLE_FAMILY_RESIDENTIAL",
    "Religious and Cultural": "RELIGIOUS_CULTURAL",
    "Education and Daycare": "EDUCATION_DAYCARE",
    "Factory | Industrial | Garage": "FACTORY_INDUSTRIAL_GARAGE",
    "Theaters & Event Spaces": "THEATERS_EVENT_SPACES",
    Other: "OTHER",
  };

  function getCost(trade, cost) {
    return parseFloat(
      ((parseFloat(cost) * disciplineCostPerSF[trade]) / 100).toFixed(3)
    );
  }

  function getTrades() {
    let trades = [];
    if (isMechSelected) trades.push("mech");
    if (isElecSelected) trades.push("elec");
    if (isPlumSelected) trades.push("plum");
    return trades;
  }

  const handleAddSpaceTemplate = async () => {
    let tempArray = [
      ...spaceTemplateList,
      {
        areaTitle: `area-${spaceTemplateList.length + 1}`,
        pricePercent: {
          mech: 100,
          elec: 100,
          plum: 100,
        },
        priceSF: {
          mech: getCost("mech", 100),
          elec: getCost("elec", 100),
          plum: getCost("plum", 100),
        },
        pricingModel: "RELATIVE_LINEAR",
        initialRatePercent: {
          mech: 100,
          elec: 100,
          plum: 100,
        },
        initialRateSF: {
          mech: getCost("mech", 100),
          elec: getCost("elec", 100),
          plum: getCost("plum", 100),
        },
        fullyDiscountedRatePercent: {
          mech: 100,
          elec: 100,
          plum: 100,
        },
        fullyDiscountedRateSF: {
          mech: getCost("mech", 100),
          elec: getCost("elec", 100),
          plum: getCost("plum", 100),
        },
        fullDiscount: {
          mech: "",
          elec: "",
          plum: "",
        },
        totalCheck: {
          mech: 0,
          elec: 0,
          plum: 0,
        },
        showTheElementLinear: "both",
        showTheElementComp: "both",
        instantQuoteWeight: 0,
      },
    ];
    setSpaceTemplateList(tempArray);
    let trades = getTrades();
    await getSpaceObj(spaceSF, tempArray, trades);
  };

  const handleOnChange = async (e, index) => {
    const { name, value } = e.target;
    const list = [...spaceTemplateList];
    list[index][name] = value;
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  };

  async function fullDiscountSF(e, index) {
    const { name, value } = e.target;
    const list = [...spaceTemplateList];
    let keys = name.split("-");
    if (value !== "") {
      list[index][keys[0]][keys[1]] = parseFloat(value);
    } else {
      list[index][keys[0]][keys[1]] = value;
    }
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function percentSFCal(e, index) {
    let { name, value } = e.target;
    if (value < 0) value = 0;
    let keys = name.split("-");
    const list = [...spaceTemplateList];
    list[index][keys[0]][keys[1]] = parseFloat(value);
    if (keys[0] === "pricePercent")
      list[index]["priceSF"][keys[1]] = parseFloat(
        ((parseFloat(value) * disciplineCostPerSF[keys[1]]) / 100).toFixed(3)
      );
    if (keys[0] === "priceSF")
      list[index]["pricePercent"][keys[1]] = parseFloat(
        ((parseFloat(value) * 100) / disciplineCostPerSF[keys[1]]).toFixed(3)
      );
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function initialPercentSFCal(e, index) {
    let { name, value } = e.target;
    if (value < 0) value = 0;
    let keys = name.split("-");
    const list = [...spaceTemplateList];
    list[index][keys[0]][keys[1]] = parseFloat(value);
    if (keys[0] === "initialRatePercent")
      list[index]["initialRateSF"][keys[1]] = parseFloat(
        ((parseFloat(value) * disciplineCostPerSF[keys[1]]) / 100).toFixed(3)
      );
    if (keys[0] === "initialRateSF")
      list[index]["initialRatePercent"][keys[1]] = parseFloat(
        ((parseFloat(value) * 100) / disciplineCostPerSF[keys[1]]).toFixed(3)
      );
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function fullyDiscountedRatePercentSFCal(e, index) {
    let { name, value } = e.target;
    if (value < 0) value = 0;
    let keys = name.split("-");
    const list = [...spaceTemplateList];
    list[index][keys[0]][keys[1]] = parseFloat(value);
    if (keys[0] === "fullyDiscountedRatePercent")
      list[index]["fullyDiscountedRateSF"][keys[1]] = parseFloat(
        ((parseFloat(value) * disciplineCostPerSF[keys[1]]) / 100).toFixed(3)
      );
    if (keys[0] === "fullyDiscountedRateSF")
      list[index]["fullyDiscountedRatePercent"][keys[1]] = parseFloat(
        ((parseFloat(value) * 100) / disciplineCostPerSF[keys[1]]).toFixed(3)
      );
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  //Handler function to remove the areas in space when the delete button is clicked
  const handleDeleteSpaceArea = async (index) => {
    const list = [...spaceTemplateList];
    list.splice(index, 1);
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  };

  function saveMarket(e) {
    if (!selectedMarkets.includes(e.target.value))
      selectedMarkets = [...selectedMarkets, e.target.value];
    setSelectedMarkets(selectedMarkets);
    setChangeInMarket(!changeInMarket);
  }

  function saveFlags(e) {
    let costFlagId = costFlags;
    let costFlagFilter = costFlags.filter((flag) => flag.id === e.id);
    if (costFlagFilter.length === 0) {
      costFlagId = [...costFlagId, { id: e.id, name: e.label }];
      setCostFlags(costFlagId);
    }
  }

  const optionFlags = () => {
    let remainingFlags = [];
    for (const flag of flagNames) {
      let isPresent = false;
      for (const costFlag of costFlags) {
        if (costFlag.id === flag.id) {
          isPresent = true;
          break;
        }
      }
      if (!isPresent) remainingFlags.push(flag);
    }
    return remainingFlags ? remainingFlags : flagNames;
  };

  const removeFlag = (costFlag) => {
    let flagList = costFlags.filter((flag) => flag.id !== costFlag.id);
    setCostFlags(flagList);
    DataStore.delete(SpaceCostFlagRelationships, (relation) =>
      relation.and((relation) => [
        relation.spaceTemplateId.eq(spaceTemplate.id),
        relation.costFlagTemplateId.eq(costFlag.id),
      ])
    );
  };

  function removeMarket(currentMarket) {
    let marketList = selectedMarkets.filter(
      (market) => market !== marketConversion[currentMarket]
    );
    setSelectedMarkets(marketList);
  }

  async function saveCostFlags(spaceId) {
    for (const flagId of costFlags) {
      const space = await DataStore.query(
        SpaceCostFlagRelationships,
        (relation) =>
          relation.and((relation) => [
            relation.spaceTemplateId.eq(spaceId),
            relation.costFlagTemplateId.eq(flagId.id),
          ])
      );
      if (space.length === 0) {
        await DataStore.save(
          new SpaceCostFlagRelationships({
            spaceTemplateId: spaceId,
            costFlagTemplateId: flagId.id,
          })
        );
      }
    }
  }

  async function deleteFromInstantQuoteSpace(spaceTemplate) {
    let currentRecord = await DataStore.query(InstantQuoteSpace, (space) =>
      space.instantQuoteSpaceSpaceTemplateId.eq(spaceTemplate.id)
    );
    if (currentRecord.length > 0) await DataStore.delete(currentRecord[0]);
  }

  async function updateSpaceTemplate(instantQuoteSapce) {
    let currentSpace = await DataStore.query(
      SpaceTemplate,
      instantQuoteSapce.instantQuoteSpaceSpaceTemplateId
    );
    await DataStore.save(
      SpaceTemplate.copyOf(currentSpace, (spaceCopy) => {
        spaceCopy.spaceTemplateInstantQuoteSpaceId = instantQuoteSapce.id;
      })
    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  async function saveToInstantQuoteSpace(spaceTemplate) {
    await DataStore.save(
      new InstantQuoteSpace({
        instantQuoteSpaceSpaceTemplateId: spaceTemplate.id,
        spaceTemplateName: spaceTemplate.templateName,
      })
    )
      .then((res) => updateSpaceTemplate(res))
      .catch((err) => console.log(err));
  }

  function checkEmptyFields(obj) {
    if (obj["mech"] === "" || obj["elec"] === "" || obj["plum"] === "") {
      return true;
    }

    return isNaN(obj["mech"] && obj["elec"] && obj["plum"]);
  }

  async function saveTemplate() {
    let areaFieldList = [];

    for (const areaField of spaceTemplateList) {
      if (isInstantQuoteSpace && areaField.instantQuoteWeight === "") {
        setIsFieldEmpty(true);
        break;
      }
      if (areaField.areaTitle.length > 0) {
        if (areaField.pricingModel === "RELATIVE_LINEAR") {
          if (checkEmptyFields(areaField.pricePercent)) break;
          areaFieldList.push({
            areaTitle: areaField.areaTitle,
            instantQuoteWeight: isInstantQuoteSpace
              ? areaField.instantQuoteWeight
              : 0,
            priceSheet: new PriceSheet({
              pricingModel: PricingModel.RELATIVE_LINEAR,
              mech: areaField.pricePercent.mech
                ? [areaField.pricePercent.mech]
                : [100],
              elec: areaField.pricePercent.elec
                ? [areaField.pricePercent.elec]
                : [100],
              plum: areaField.pricePercent.plum
                ? [areaField.pricePercent.plum]
                : [100],
            }),
          });
        } else {
          if (checkEmptyFields(areaField.initialRatePercent)) break;

          if (checkEmptyFields(areaField.fullyDiscountedRatePercent)) break;

          if (checkEmptyFields(areaField.fullDiscount)) break;

          areaFieldList.push({
            areaTitle: areaField.areaTitle,
            instantQuoteWeight: isInstantQuoteSpace
              ? areaField.instantQuoteWeight
              : 0,
            priceSheet: new PriceSheet({
              pricingModel: PricingModel.COMPRESSED,
              mech: [
                areaField.initialRatePercent.mech
                  ? areaField.initialRatePercent.mech
                  : 100,
                areaField.fullyDiscountedRatePercent.mech
                  ? areaField.fullyDiscountedRatePercent.mech
                  : 100,
                areaField.fullDiscount.mech ? areaField.fullDiscount.mech : 0,
                areaField.totalCheck.mech ? areaField.totalCheck.mech : 0,
              ],
              elec: [
                areaField.initialRatePercent.elec
                  ? areaField.initialRatePercent.elec
                  : 100,
                areaField.fullyDiscountedRatePercent.elec
                  ? areaField.fullyDiscountedRatePercent.elec
                  : 100,
                areaField.fullDiscount.elec ? areaField.fullDiscount.elec : 0,
                areaField.totalCheck.elec ? areaField.totalCheck.elec : 0,
              ],
              plum: [
                areaField.initialRatePercent.plum
                  ? areaField.initialRatePercent.plum
                  : 100,
                areaField.fullyDiscountedRatePercent.plum
                  ? areaField.fullyDiscountedRatePercent.plum
                  : 100,
                areaField.fullDiscount.plum ? areaField.fullDiscount.plum : 0,
                areaField.totalCheck.plum ? areaField.totalCheck.plum : 0,
              ],
            }),
          });
        }
      }
    }
    setIsFieldEmpty(!(areaFieldList.length === spaceTemplateList.length));
    if (
      !nameError &&
      !isMultipleSpace &&
      isAlphaNumeric &&
      !isTrailingSpace &&
      templateName &&
      areaFieldList.length === spaceTemplateList.length
    ) {
      if (mode === "edit")
        await DataStore.save(
          SpaceTemplate.copyOf(spaceTemplate, (spaceTemplateCopy) => {
            spaceTemplateCopy.templateName = templateName;
            spaceTemplateCopy.areas = areaFieldList;
            spaceTemplateCopy.markets = selectedMarkets;
            spaceTemplateCopy.priceSheet = new PriceSheet({
              pricingModel: PricingModel.SPACE_SETUP,
              mech: [
                setUpFee["mech"][0] ? setUpFee["mech"][0] : 0,
                setUpFee["mech"][1] ? setUpFee["mech"][1] : 0,
              ],
              elec: [
                setUpFee["elec"][0] ? setUpFee["elec"][0] : 0,
                setUpFee["elec"][1] ? setUpFee["elec"][1] : 0,
              ],
              plum: [
                setUpFee["plum"][0] ? setUpFee["plum"][0] : 0,
                setUpFee["plum"][1] ? setUpFee["plum"][1] : 0,
              ],
            });
            spaceTemplateCopy.spaceTemplateInstantQuoteSpaceId =
              isInstantQuoteSpace
                ? spaceTemplateCopy.spaceTemplateInstantQuoteSpaceId
                : null;
          })
        )
          .then((res) => {
            if (isInstantQuoteSpace && !res.spaceTemplateInstantQuoteSpaceId)
              saveToInstantQuoteSpace(res);
            else if (!isInstantQuoteSpace) deleteFromInstantQuoteSpace(res);
            saveCostFlags(res.id);
            uploadImage(res);
          })
          .catch((err) => console.log(err));
      else
        await DataStore.save(
          new SpaceTemplate({
            templateName: templateName,
            areas: areaFieldList,
            markets: selectedMarkets,
            priceSheet: new PriceSheet({
              pricingModel: PricingModel.SPACE_SETUP,
              mech: [
                setUpFee["mech"][0] ? setUpFee["mech"][0] : 0,
                setUpFee["mech"][1] ? setUpFee["mech"][1] : 0,
              ],
              elec: [
                setUpFee["elec"][0] ? setUpFee["elec"][0] : 0,
                setUpFee["elec"][1] ? setUpFee["elec"][1] : 0,
              ],
              plum: [
                setUpFee["plum"][0] ? setUpFee["plum"][0] : 0,
                setUpFee["plum"][1] ? setUpFee["plum"][1] : 0,
              ],
            }),
            auxInputs: [],
          })
        )
          .then((res) => {
            if (isInstantQuoteSpace) saveToInstantQuoteSpace(res);
            saveCostFlags(res.id);
            uploadImage(res);
          })
          .catch((err) => console.log(err));
    } else {
      console.log(isFieldEmpty);
      // Swal.fire({
      //   icon: "error",
      //   title: "Template Not Saved",
      //   text:
      //     templateName.length === 0
      //       ? "Template name is required"
      //       : nameError
      //       ? "Template name already exist"
      //       : !isAlphaNumeric
      //       ? "Template name should be alpha numeric"
      //       : isMultipleSpace
      //       ? "Template name should not contain multiple space"
      //       : isTrailingSpace
      //       ? "Template name should not end with a space"
      //       : !(areaFieldList.length === spaceTemplateList.length) &&
      //         "Area fields should not be empty",
      // });
    }
  }

  //To delete the space template with associated image
  function handleDeleteSpaceTemplate() {
    Swal.fire({
      title: "Do you want to delete this space template",
      icon: "warning",
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: `Go Back`,
      denyButtonText: "Delete",
      confirmButtonColor: "#40aabf",
      denyButtonColor: "#bf4040",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDenied) {
        await deleteSpaceTemplate().then(() => {
          Swal.fire({
            title: "Deleted!",
            text: "The space template has been deleted.",
            icon: "success",
          }).then(() => onClose());
        });
      }
    });
  }

  //helper function for handleDeleteSpaceTemplate
  async function deleteSpaceTemplate() {
    console.log(spaceTemplate.imageKey);
    const storageKey = `assets/spaceImages/${spaceTemplate.imageKey}`;
    try {
      if (spaceTemplate.imageKey) {
        await Storage.remove(storageKey).then((response) =>
          console.log(response)
        );
      }
      await DataStore.delete(spaceTemplate).then((response) =>
        console.log(response)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function changeInName(e) {
    setTemplateName(e.target.value);
    let spaceTemplates = [];
    if (spaceTemplate)
      spaceTemplates = await DataStore.query(SpaceTemplate, (space) =>
        space.and((space) => [
          space.templateName.eq(e.target.value),
          space.id.ne(spaceTemplate.id),
        ])
      );
    else
      spaceTemplates = await DataStore.query(SpaceTemplate, (space) =>
        space.templateName.eq(e.target.value)
      );
    setNameError(spaceTemplates.length > 0);
    setIsAlphaNumeric(/^[a-zA-Z0-9\s]+$/.test(e.target.value));
    setIsMultipleSpace(e.target.value.split(" ").slice(0, -1).includes(""));
    setIsTrailingSpace(
      e.target.value.split(" ")[e.target.value.split(" ").length - 1] === ""
    );
  }

  function getTotalSpaceSetUpFee() {
    let total = 0;
    if (!isNaN(setUpFee["mech"][0])) total += parseFloat(setUpFee["mech"][0]);
    if (!isNaN(setUpFee["elec"][0])) total += parseFloat(setUpFee["elec"][0]);
    if (!isNaN(setUpFee["plum"][0])) total += parseFloat(setUpFee["plum"][0]);
    setUpFee["total"][0] = total;
  }

  async function changeInitialSetUpFee(e) {
    if (e.target.value < 0) e.target.value = 0;
    if (e.target.value === 0) return;
    setUpFee[e.target.name] = [
      parseFloat(e.target.value),
      setUpFee[e.target.name][1],
    ];
    getTotalSpaceSetUpFee();
    setSetUpFee(setUpFee);
    setChangeInSetUpFee(!changeInSetUpFee);
    // await getSpaceObj(spaceSF, spaceTemplateList);
  }

  async function changeIterativeSetUpFee(e) {
    if (e.target.value < 0) e.target.value = 0;
    setUpFee[e.target.name] = [
      setUpFee[e.target.name][0],
      parseFloat(e.target.value),
    ];
    setSetUpFee(setUpFee);
    setChangeInSetUpFee(!changeInSetUpFee);
    // await getSpaceObj(spaceSF, spaceTemplateList);
  }

  function setPricingType(e, index) {
    let { name, value } = e.target;
    const list = [...spaceTemplateList];
    list[index][name] = value;
    setSpaceTemplateList(list);
  }

  const handleRadioButtonChange = (event, index) => {
    const list = [...spaceTemplateList];
    console.log(list);
    let name = event.target.name;
    let value = event.target.value;
    console.log(name, value);
    if (name && value) {
      let nameKey = name.split("-")[0];
      console.log(nameKey);
      if (value) {
        list[index][nameKey] = value;
      } else if (nameKey) {
        list[index][nameKey] = "both";
      }
      setSpaceTemplateList(list);
    }
  };

  async function updateSpaceTemplateImage(space) {
    await DataStore.save(
      SpaceTemplate.copyOf(space, (spaceTemplateCopy) => {
        spaceTemplateCopy.imageKey = space.id;
      })
    )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Template Saved",
        }).then((res) => onClose());
      })
      .catch((err) => console.log(err));
  }

  async function uploadImage(space) {
    if (selectedImage)
      await Storage.put(`assets/spaceImages/${space.id}`, selectedImage)
        .then((e) => {
          updateSpaceTemplateImage(space);
        })
        .catch((err) => console.log(err));
    else {
      Swal.fire({
        icon: "success",
        title: "Template Saved",
      }).then((res) => onClose());
    }
  }

  async function changeInWeight(event, index) {
    const list = [...spaceTemplateList];
    let { name, value } = event.target;
    if (value < 0) value = 0;
    list[index][name] = parseFloat(value);
    let total = 0;
    for (const area of list) {
      if (!isNaN(area.instantQuoteWeight)) total += area.instantQuoteWeight;
    }
    console.log(total);
    setIsSumOfAreaAssignments(!(isNaN(total) || total === 0));
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function getSpaceObj(val, spaceList, trades) {
    let newSpace = {
      customName: templateName,
      className: templateName,
      areas: [],
      priceSheet: new PriceSheet({
        pricingModel: PricingModel.SPACE_SETUP,
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
      }),
    };
    for (const input of spaceList) {
      // console.log(input);
      let areaPriceSheet = {};
      if (input.pricingModel === "RELATIVE_LINEAR") {
        areaPriceSheet = {
          pricingModel: PricingModel.RELATIVE_LINEAR,
          mech: input.pricePercent.mech ? [input.pricePercent.mech] : [100],
          elec: input.pricePercent.elec ? [input.pricePercent.elec] : [100],
          plum: input.pricePercent.plum ? [input.pricePercent.plum] : [100],
        };
      } else {
        areaPriceSheet = {
          pricingModel: PricingModel.COMPRESSED,
          mech: [
            input.initialRatePercent.mech ? input.initialRatePercent.mech : 100,
            input.fullyDiscountedRatePercent.mech
              ? input.fullyDiscountedRatePercent.mech
              : 100,
            input.fullDiscount.mech ? input.fullDiscount.mech : 0,
            input.totalCheck.mech ? input.totalCheck.mech : 0,
          ],
          elec: [
            input.initialRatePercent.elec ? input.initialRatePercent.elec : 100,
            input.fullyDiscountedRatePercent.elec
              ? input.fullyDiscountedRatePercent.elec
              : 100,
            input.fullDiscount.elec ? input.fullDiscount.elec : 0,
            input.totalCheck.elec ? input.totalCheck.elec : 0,
          ],
          plum: [
            input.initialRatePercent.plum ? input.initialRatePercent.plum : 100,
            input.fullyDiscountedRatePercent.plum
              ? input.fullyDiscountedRatePercent.plum
              : 100,
            input.fullDiscount.plum ? input.fullDiscount.plum : 0,
            input.totalCheck.plum ? input.totalCheck.plum : 0,
          ],
        };
      }
      let spaceInputField = {
        areaTitle: input.areaTitle,
        instantQuoteWeight: input.instantQuoteWeight,
        area: parseFloat((val * input.instantQuoteWeight) / 100),
        priceSheet: areaPriceSheet,
      };
      newSpace["areas"].push(spaceInputField);
    }
    console.log(newSpace);
    let space = await ProjectCalculator(null, null, null, "", newSpace, trades);
    console.log(space);
    setCalculatedSpace(space);
  }

  const customCss = `
  .outlineBar{
    outline: 1px solid rgba(191, 191, 191, 0.80);
    z-index:1;
  }
  .cursor{
    cursor:pointer;
  }
  .closeBtn{
    transition:0.5s ease;
    cursor:pointer;
    background-color:#c5c5c5;
  }
  .closeBtn:hover{
    background-color:lightblue;
  }
  .hover:hover{
    -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    transition: 0.1s ease-in;
  }
    .amplify-table__td, .amplify-table__th{
      border:1px solid #c5c5c5;
      // width:auto
    }
    .inputField{
      min-width:40px;
      max-width:80px;
      margin:0px 5px;
    }
    .emptyField{
      border:1px solid #b34545
    }
    .upload-form{
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      height:300px;
      width:500px;
      cursor:pointer;
      border-radius:10px;
    }
    .preview-img{
      width:100%;
      height:100%;
      padding:15px;
      border-radius:20px;
      overflow:hidden;
      object-fit:cover;
    }
    .amplify-sliderfield__label {
      display: flex;
      justify-content: center;
      font-size:20px;
  }
  .amplify-sliderfield__thumb {
      border:none;
      background-color:#367588;
      border-radius:5px;
      height:22px;
      width:22px;
      cursor:pointer;
  }
  .amplify-sliderfield__thumb:hover {
      border:none;
      background-color:#367588;
  }
  .amplify-sliderfield__thumb:focus {
      box-shadow:none;
  }
    `;

  const darkCss = `
    .amplify-select {
      background:#191f21;
    }
    .amplify-searchfield {
      color:#fff;
    }
    .amplify-flex.amplify-field.amplify-textfield.amplify-searchfield {
      color:#fff;

    }
    .amplify-autocomplete__menu{
      color:#fff;
      background:#2e3b36;
    }
    .closeBtn:hover{
      background-color:lightblue;
    }
    `;

  function getMechTotal() {
    let total = 0;
    if (isMechSelected) {
      total = parseFloat(calculatedSpace.priceSheet.mechTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.mech);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.mech[0])) total += parseFloat(setUpFee.mech[0]);
      }
    }
    return total;
  }

  function getElecTotal() {
    let total = 0;
    if (isElecSelected) {
      total = parseFloat(calculatedSpace.priceSheet.elecTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.elec);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.elec[0])) total += parseFloat(setUpFee.elec[0]);
      }
    }
    return total;
  }

  function getPlumTotal() {
    let total = 0;
    if (isPlumSelected) {
      total = parseFloat(calculatedSpace.priceSheet.plumTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.plum);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.plum[0])) total += parseFloat(setUpFee.plum[0]);
      }
    }
    return total;
  }

  return (
    <>
      <style>{colorMode === "dark" ? customCss + darkCss : customCss}</style>
      <Flex
        width={"100%"}
        alignItems={"flex-start"}
        direction={"column"}
        // position={"relative"}
        gap={"0"}
      >
        <Flex
          className="outlineBar"
          padding="15px 70px 10px 34px"
          alignItems="center"
          boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
          width={"100%"}
          gap={"0"}
          position={"sticky"}
          top={"190px"}
          backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
        >
          <Button
            variation="link"
            textAlign={"center"}
            padding={"10px 25px 10px 5px"}
            margin={"0px 15px"}
            onClick={onClose}
          >
            <Icon
              ariaLabel="Close"
              fontSize={"25px"}
              pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
            />
          </Button>
          <Heading margin={"5px"} padding={"0"} level={4}>
            {mode === "edit" ? "Update Space Template" : "Add Space Template"}
          </Heading>
        </Flex>

        <Flex
          direction={"row"}
          margin={"15px 20px 15px 50px"}
          minWidth={"500px"}
          className="zIndex"
          gap={"25px"}
          paddingBottom={"15px"}
        >
          <Flex direction={"column"} maxWidth={"700px"}>
            <TextField
              label="Space Name"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={"1px solid #c5c5c5"}
              padding={"20px 15px"}
              borderRadius={"10px"}
              fontSize={"20px"}
              placeholder="Enter the Space Name"
              size="default"
              onFocus={() => setFocusOut(false)}
              value={templateName}
              onBlur={() => {
                setFocusOut(true);
              }}
              hasError={
                (templateName.length > 0 &&
                  (nameError || !isAlphaNumeric || isMultipleSpace)) ||
                (focusOut === true &&
                  (templateName.length === 0 || isTrailingSpace))
              }
              errorMessage={
                templateName.length === 0
                  ? "Template name is required"
                  : nameError
                  ? "Template name already exist"
                  : !isAlphaNumeric
                  ? "Template name should be alpha numeric"
                  : isMultipleSpace
                  ? "Template name should not contain multiple space"
                  : isTrailingSpace &&
                    "Template name should not end with a space"
              }
              onChange={(e) => changeInName(e)}
            />

            <Flex
              direction={"column"}
              border={"1px solid #c5c5c5"}
              padding={"15px"}
              borderRadius={"10px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <Text fontSize={"20px"}>Setup Fees - First Instance ($)</Text>
              <Flex direction={"row"} gap={"20px"}>
                <TextField
                  label="Mechanical"
                  type="number"
                  name="mech"
                  placeholder="0"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  value={
                    setUpFee["mech"][0]
                      ? setUpFee["mech"][0]
                      : setUpFee["mech"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Electrical"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="elec"
                  value={
                    setUpFee["elec"][0]
                      ? setUpFee["elec"][0]
                      : setUpFee["elec"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Plumbing"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="plum"
                  value={
                    setUpFee["plum"][0]
                      ? setUpFee["plum"][0]
                      : setUpFee["plum"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
              </Flex>
              <Text fontSize={"20px"}>
                Setup Fees - Subsequent Instances ($)
              </Text>
              <Flex direction={"row"} gap={"20px"}>
                <TextField
                  label="Mechanical"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="mech"
                  value={
                    setUpFee["mech"][1]
                      ? setUpFee["mech"][1]
                      : setUpFee["mech"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Electrical"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="elec"
                  value={
                    setUpFee["elec"][1]
                      ? setUpFee["elec"][1]
                      : setUpFee["elec"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Plumbing"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="plum"
                  value={
                    setUpFee["plum"][1]
                      ? setUpFee["plum"][1]
                      : setUpFee["plum"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              border={"1px solid #c5c5c5"}
              padding={"15px"}
              borderRadius={"10px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <SelectField
                fontSize={"20px"}
                label="Select Markets"
                onChange={(e) => saveMarket(e)}
                placeholder="Select one or more Market tags for searching"
              >
                {Object.keys(marketConversion).map((market, index) => {
                  if (!selectedMarkets.includes(marketConversion[market]))
                    return (
                      <option key={index} value={marketConversion[market]}>
                        {market}
                      </option>
                    );
                })}
              </SelectField>
              <Flex wrap={"wrap"}>
                {Object.keys(marketConversion).map((market, index) => {
                  if (selectedMarkets.includes(marketConversion[market]))
                    return (
                      <Badge key={index} alignItems="center">
                        {market}
                        <Icon
                          className="closeBtn"
                          borderRadius={"50%"}
                          fontSize={"20px"}
                          marginLeft={"12px"}
                          textAlign={"center"}
                          onClick={() => removeMarket(market)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#000"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                          </svg>
                        </Icon>
                      </Badge>
                    );
                })}
              </Flex>
            </Flex>

            <Flex
              direction={"column"}
              border={"1px solid #c5c5c5"}
              padding={"15px 20px"}
              borderRadius={"10px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <Text fontSize={"20px"}>Add Area</Text>

              {spaceTemplateList.map((singleArea, index) => (
                <Flex direction={"column"} key={index}>
                  <Card
                    borderRadius={"10px"}
                    margin={"10px 0"}
                    // backgroundColor={colorMode === "dark" && "#304050"}
                    border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
                  >
                    <TextField
                      name="areaTitle"
                      marginBottom={"20px"}
                      value={singleArea.areaTitle}
                      onChange={(e) => handleOnChange(e, index)}
                      outerEndComponent={
                        <Button
                          onClick={() => handleDeleteSpaceArea(index)}
                          disabled={spaceTemplateList.length <= 1}
                        >
                          Delete
                        </Button>
                      }
                      placeholder="Enter the Area Name"
                    />
                    <SelectField
                      margin={"15px 0"}
                      onChange={(e) => {
                        setPricingType(e, index);
                      }}
                      name="pricingModel"
                      value={singleArea["pricingModel"]}
                    >
                      <option value="RELATIVE_LINEAR">Linear</option>
                      <option value="COMPRESSED">Compressed</option>
                    </SelectField>
                    {singleArea["pricingModel"] === "RELATIVE_LINEAR" ? (
                      <Flex direction="column">
                        {" "}
                        {/* Linear Chart  */}
                        <RadioGroupField
                          id={index}
                          name={"showTheElementLinear-" + index}
                          checked={singleArea["showTheElementLinear"]}
                          width={"fit-content"}
                          direction={"row"}
                          label="Show: "
                          gap={"10px"}
                          onClick={(e) => {
                            console.log(e.target.value);
                            handleRadioButtonChange(e, index);
                          }}
                        >
                          <Radio value="percent">%</Radio>
                          <Radio value="sF">$/SF</Radio>
                          <Radio value="both" defaultChecked>
                            Both
                          </Radio>
                        </RadioGroupField>
                        <Table variation="bordered" size="small">
                          <TableRow>
                            <TableCell border={"none"}></TableCell>
                            <TableCell>Mech</TableCell>
                            <TableCell>Elec</TableCell>
                            <TableCell>Plum</TableCell>
                          </TableRow>
                          {(singleArea["showTheElementLinear"] === "percent" ||
                            singleArea["showTheElementLinear"] === "both") && (
                            <TableRow>
                              <TableCell>
                                Cost relative to Base Pricing:
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-mech"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["pricePercent"]["mech"]}
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-elec"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["pricePercent"]["elec"]}
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-plum"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={singleArea["pricePercent"]["plum"]}
                                ></input>{" "}
                                %
                              </TableCell>
                            </TableRow>
                          )}
                          {(singleArea["showTheElementLinear"] === "sF" ||
                            singleArea["showTheElementLinear"] === "both") && (
                            <TableRow>
                              <TableCell minWidth={"200px"}>
                                Actual Cost:
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.3"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-mech"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["mech"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.28"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-elec"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["elec"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.28"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-plum"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["plum"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      </Flex>
                    ) : (
                      <Flex direction="column">
                        {" "}
                        {/* Compressed Chart  */}
                        <RadioGroupField
                          id={index}
                          name={"showTheElementComp-" + index}
                          checked={singleArea["showTheElementComp"]}
                          width={"fit-content"}
                          direction={"row"}
                          label="Show: "
                          gap={"10px"}
                          onClick={(e) => {
                            handleRadioButtonChange(e, index);
                          }}
                        >
                          <Radio value="sF">$/SF</Radio>
                          <Radio value="percent">%</Radio>
                          <Radio value="both" defaultChecked>
                            Both
                          </Radio>
                        </RadioGroupField>
                        <Text>
                          Please use{" "}
                          <Link href={compressedCalculatorLink} target="_blank">
                            this calculator
                          </Link>{" "}
                          to preview compressed pricing settings.{" "}
                          <b>
                            Remember when setting SF requirements that each area
                            size is assessed independently.
                          </b>
                        </Text>
                        <Table variation="bordered" size="small">
                          <TableRow>
                            <TableCell border={"none"}></TableCell>
                            <TableCell>Mech</TableCell>
                            <TableCell>Elec</TableCell>
                            <TableCell>Plum</TableCell>
                          </TableRow>
                          {(singleArea["showTheElementComp"] === "sF" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow>
                              <TableCell>Initial rate ($/SF):</TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-mech"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["mech"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-elec"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["elec"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-plum"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["plum"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                            </TableRow>
                          )}
                          {(singleArea["showTheElementComp"] === "percent" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow
                              style={{
                                fontStyle: "italic",
                                opacity: "0.6",
                              }}
                            >
                              <TableCell
                                style={{
                                  paddingLeft: "25px",
                                }}
                              >
                                Initial rate (%):
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-mech"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["initialRatePercent"]["mech"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-elec"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["initialRatePercent"]["elec"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-plum"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    singleArea["initialRatePercent"]["plum"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow
                            style={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                            }}
                          >
                            <TableCell
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Size for full discount (SF):
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["mech"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-mech"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={
                                  singleArea["fullDiscount"]["mech"]
                                  // ? singleArea["fullDiscount"]["mech"]
                                  // : singleArea["fullDiscount"]["mech"] ===
                                  //     "" && 0
                                }
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["elec"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-elec"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={
                                  singleArea["fullDiscount"]["elec"]
                                  // ? singleArea["fullDiscount"]["elec"]
                                  // : singleArea["fullDiscount"]["elec"] ===
                                  //     "" && 0
                                }
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["plum"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-plum"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={
                                  singleArea["fullDiscount"]["plum"]
                                  // ? singleArea["fullDiscount"]["plum"]
                                  // : singleArea["fullDiscount"]["plum"] ===
                                  //     "" && 0
                                }
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                          </TableRow>
                          {(singleArea["showTheElementComp"] === "sF" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow>
                              <TableCell>
                                Fully discounted rate ($/SF):
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-mech"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["mech"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-elec"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["elec"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-plum"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["plum"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                            </TableRow>
                          )}

                          {(singleArea["showTheElementComp"] === "percent" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow
                              style={{
                                fontStyle: "italic",
                                opacity: "0.6",
                              }}
                            >
                              <TableCell
                                style={{
                                  paddingLeft: "25px",
                                }}
                              >
                                Fully discounted rate (%):
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "mech"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-mech"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "mech"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "elec"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-elec"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "elec"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "plum"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-plum"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "plum"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      </Flex>
                    )}
                  </Card>
                  {spaceTemplateList.length - 1 === index && (
                    <Button onClick={() => handleAddSpaceTemplate()}>
                      Add Area
                    </Button>
                  )}
                </Flex>
              ))}
            </Flex>
            {(nameError ||
              isMultipleSpace ||
              !isAlphaNumeric ||
              isTrailingSpace ||
              templateName.length === 0 ||
              isFieldEmpty) && (
              <Alert variation="warning" width={"100%"} id="warn">
                {templateName.length === 0
                  ? "Template name is required"
                  : nameError
                  ? "Template name already exist"
                  : !isAlphaNumeric
                  ? "Template name should be alpha numeric"
                  : isMultipleSpace
                  ? "Template name should not contain multiple space"
                  : isTrailingSpace
                  ? "Template name should not end with a space"
                  : isFieldEmpty && "Area fields should not be empty"}
              </Alert>
            )}
            <Button
              width={"100%"}
              // marginTop={"20px"}
              fontSize="18px"
              color={"#fff"}
              fontWeight="600"
              backgroundColor={"brand.primary.80"}
              onClick={() => saveTemplate()}
            >
              {mode === "edit" ? "Update Space Template" : "Add Space Template"}
            </Button>
            {mode === "edit" && (
              <Button
                width={"100%"}
                fontSize="18px"
                color={"#fff"}
                fontWeight="600"
                onClick={() => handleDeleteSpaceTemplate()}
                variation="destructive"
              >
                Delete Space Template
              </Button>
            )}
          </Flex>

          <Flex
            width={"auto"}
            height={"fit-content"}
            margin={"0 20px"}
            direction={"column"}
            maxWidth={"45%"}
          >
            <form
              className="upload-form"
              onClick={() =>
                document.querySelector(".file-input-field").click()
              }
              backgroundColor={colorMode === "dark" && "#2e3436"}
              style={{
                border: `${
                  colorMode === "dark"
                    ? "2px dashed #f4f4f4"
                    : "2px dashed #c5c5c5"
                }`,
                aspectRatio: "4/3",
                width: "100%",
                height: "auto",
              }}
            >
              <input
                type="file"
                onChange={(e) => {
                  setSelectedImage(e.target.files[0]);
                }}
                accept="image/*"
                className="file-input-field"
                hidden
              />

              {selectedImage ? (
                <img
                  src={selectedImage && URL.createObjectURL(selectedImage)}
                  className="preview-img"
                  alt="preview img"
                />
              ) : tempImage ? (
                <img
                  src={tempImage}
                  className="preview-img"
                  alt="preview img"
                />
              ) : (
                <>
                  <svg
                    fill={`${colorMode === "dark" ? "#f4f4f4" : "#c5c5c5"}`}
                    height="150px"
                    width="150px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    enableBackground="new 0 0 512 512"
                  >
                    <path
                      d="M470.7,277.2c3-11.2,4.7-22.9,4.7-35c0-75.8-61.4-137.1-137.1-137.1c-19.5,0-38,4.1-54.7,11.4
                        c-16.8-39-55.6-66.3-100.7-66.3c-60.6,0-109.7,49.1-109.7,109.7c0,4.1,0.8,7.9,1.2,11.9C30.5,192.1,0,236.3,0,287.9
                        c0,70.7,57.3,128,128,128h310.9c40.4,0,73.1-32.7,73.1-73.1C512,313.8,495.1,289.1,470.7,277.2z M292.6,251.3v91.4h-73.1v-91.4
                        h-54.9l91.4-91.4l91.4,91.4H292.6z"
                    />
                  </svg>
                  <Text fontSize={"20px"} fontWeight={"500"}>
                    Browse files
                  </Text>
                </>
              )}
            </form>

            <Flex
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={"1px solid #c5c5c5"}
              padding={"15px 20px"}
              borderRadius={"10px"}
              justifyContent={"space-between"}
            >
              <View>
                <Text>
                  {selectedImage
                    ? selectedImage?.name
                    : tempImage
                    ? templateName + ".jpg"
                    : "No image file selected"}
                </Text>
              </View>

              {(selectedImage || tempImage) && (
                <svg
                  className="cursor"
                  fill={`${colorMode === "dark" ? "#f4f4f4" : "#c5c5c5"}`}
                  height="25px"
                  viewBox="0 0 24 24"
                  onClick={() => {
                    setSelectedImage(null);
                    setTempImage(null);
                  }}
                >
                  <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z" />
                </svg>
              )}
            </Flex>

            <Flex
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={"1px solid #c5c5c5"}
              padding={"15px 20px"}
              borderRadius={"10px"}
              justifyContent={"space-between"}
            >
              <CheckboxField
                label="Feature this Space on the Instant Quote"
                name="subscribe"
                checked={isInstantQuoteSpace}
                onChange={(e) => setIsInstantQuoteSpace(e.target.checked)}
              />
            </Flex>
            {isInstantQuoteSpace && (
              <>
                <Flex
                  backgroundColor={colorMode === "dark" && "#2e3436"}
                  border={"1px solid #c5c5c5"}
                  padding={"15px 20px"}
                  borderRadius={"10px"}
                  justifyContent={"space-between"}
                  direction={"column"}
                >
                  <Flex direction={"column"} gap={"2"} width={"100%"}>
                    <Heading level={5}>Automatic Area Assignments</Heading>
                    <Text fontSize={"16px"} fontStyle={"italic"}>
                      Assign Weighted Area Distributions for Instant Quote
                      submissions
                    </Text>
                  </Flex>
                  {spaceTemplateList.map((singleArea, index) => (
                    <Flex direction={"column"} gap={"0"} key={index}>
                      <Text fontSize={"18px"}>{singleArea.areaTitle}</Text>
                      <TextField
                        maxWidth={"160px"}
                        fontSize={"20px"}
                        padding={"5px"}
                        type="number"
                        name="instantQuoteWeight"
                        value={
                          singleArea["instantQuoteWeight"]
                            ? singleArea["instantQuoteWeight"]
                            : singleArea["instantQuoteWeight"] === "" && 0
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => changeInWeight(e, index)}
                        onFocus={(e) => e.target.select()}
                        placeholder="0"
                        outerEndComponent={
                          <Text
                            borderRadius={"0 4px 4px 0"}
                            border={"1px solid #89949f"}
                            color={"#89949f"}
                            height={"100%"}
                            paddingTop={"4px"}
                            padding={"3px 10px 0 10px"}
                            style={{ borderLeft: "none" }}
                          >
                            %
                          </Text>
                        }
                      />
                    </Flex>
                  ))}
                </Flex>
                <Flex
                  backgroundColor={colorMode === "dark" && "#2e3436"}
                  border={"1px solid #c5c5c5"}
                  padding={"15px 10px"}
                  borderRadius={"10px"}
                  justifyContent={"space-between"}
                  direction={"column"}
                >
                  <Flex direction={"column"} gap={"8px"}>
                    <Heading level={5}>Instant Quote Pricing Preview</Heading>
                    <Text fontSize={"16px"} fontStyle={"italic"}>
                      Set size (SF) to quickly check pricing totals and
                      breakdown
                    </Text>
                  </Flex>
                  {!isSumOfAreaAssignments ? (
                    <Alert
                      variation="warning"
                      fontStyle={"italic"}
                      width={"fit-content"}
                    >
                      Configure Automatic Area Assignments to preview instant
                      quote pricing!
                    </Alert>
                  ) : (
                    <>
                      <Text
                        fontSize={"120%"}
                        fontWeight={"500"}
                        marginTop={"10px"}
                      >
                        Space Size (SF)
                      </Text>
                      <Flex
                        alignItems={"flex-end"}
                        justifyContent={"center"}
                        minHeight={"100px"}
                        padding={"0 15px"}
                        gap={"0"}
                        width={"auto"}
                      >
                        <Text marginBottom={"8px"}>0</Text>
                        <Flex
                          direction={"column"}
                          // width={"100%"}
                          // maxWidth={"500px"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Flex
                            alignItems={"flex-end"}
                            justifyContent={"center"}
                            gap={"3px"}
                          >
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              value={spaceSF}
                              style={{
                                minWidth: "40px",
                                maxWidth: "70px",
                                width: "fit-content",
                                padding: "3px",
                                "border-radius": "5px",
                                border: "1px solid #89949f",
                                backgroundColor: `${
                                  colorMode === "dark" && "#2e3436"
                                }`,
                              }}
                              onChange={(e) => {
                                setSpaceSF(e.target.value);
                                let trades = getTrades();
                                getSpaceObj(
                                  e.target.value,
                                  spaceTemplateList,
                                  trades
                                );
                              }}
                            />{" "}
                            <Text fontSize={"120%"} marginBottom={"3px"}>
                              SF
                            </Text>
                          </Flex>
                          <SliderField
                            overflow={"hidden"}
                            className="custom-slider"
                            justifyContent={"center"}
                            alignItems={"center"}
                            // width={"80%"}
                            padding={"0 15px"}
                            minWidth={"400px"}
                            orientation={"horizontal"}
                            min={0}
                            max={maxSF}
                            labelHidden
                            value={spaceSF}
                            onChange={(val) => {
                              setSpaceSF(val);
                              console.log(val);
                              let trades = getTrades();
                              getSpaceObj(val, spaceTemplateList, trades);
                            }}
                          />
                        </Flex>
                        <Flex
                          alignItems={"flex-end"}
                          justifyContent={"center"}
                          gap={"3px"}
                        >
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onFocus={(e) => e.target.select()}
                            value={maxSF}
                            style={{
                              minWidth: "50px",
                              maxWidth: "70px",
                              // "margin-top": "40px",
                              width: "fit-content",
                              padding: "3px",
                              "border-radius": "5px",
                              border: "1px solid #89949f",
                              backgroundColor: `${
                                colorMode === "dark" && "#2e3436"
                              }`,
                            }}
                            onChange={(e) => {
                              setMaxSF(e.target.value);
                            }}
                          />{" "}
                          <Text fontSize={"120%"} marginBottom={"5px"}>
                            SF
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex margin={"10px 0 5px 0"}>
                        <Text fontSize={"120%"} fontWeight={"500"}>
                          Total Cost:
                        </Text>
                        <Flex gap={"0"} direction={"column"}>
                          <Text fontSize={"120%"} fontWeight={"500"}>
                            $
                            {formatNumberUS(
                              getMechTotal() + getElecTotal() + getPlumTotal()
                            )}
                          </Text>
                          <Text
                            fontSize={"90%"}
                            fontWeight={"400"}
                            fontStyle={"italic"}
                          >
                            {!isNaN(
                              (getMechTotal() +
                                getElecTotal() +
                                getPlumTotal()) /
                                spaceSF
                            ) &&
                            getMechTotal() + getElecTotal() + getPlumTotal() > 0
                              ? `($${formatNumberUS(
                                  (getMechTotal() +
                                    getElecTotal() +
                                    getPlumTotal()) /
                                    spaceSF,
                                  3
                                )}/SF)`
                              : `$0/SF`}
                          </Text>
                        </Flex>
                      </Flex>
                      {!includeProjectSetUpFee && (
                        <>
                          <Alert
                            variation="info"
                            width={"fit-content"}
                            fontStyle={"italic"}
                          >
                            Does not include{" "}
                            <b>${formatNumberUS(projectSetUpFee.total)}</b> in
                            project setup fees
                          </Alert>
                        </>
                      )}
                      {!includeSpaceSetUpFee && (
                        <>
                          <Alert
                            variation="info"
                            width={"fit-content"}
                            fontStyle={"italic"}
                          >
                            Does not include{" "}
                            <b>${formatNumberUS(setUpFee.total[0])}</b> in Space
                            setup fees
                          </Alert>
                        </>
                      )}
                      <CheckboxField
                        label="Include space setup fees"
                        name="subscribe"
                        checked={includeSpaceSetUpFee}
                        onChange={(e) =>
                          setIncludeSpaceSetUpFee(e.target.checked)
                        }
                      />
                      <CheckboxField
                        label="Include project setup fees"
                        name="subscribe"
                        checked={includeProjectSetUpFee}
                        onChange={(e) =>
                          setIncludeProjectSetUpFee(e.target.checked)
                        }
                      />
                      <CheckboxField
                        label="Mechanical"
                        name="subscribe"
                        checked={isMechSelected}
                        onChange={(e) => {
                          setIsMechSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("mech")) {
                            trades.splice(trades.indexOf("mech"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("mech")
                          ) {
                            trades.push("mech");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />
                      <CheckboxField
                        label="Electrical"
                        name="subscribe"
                        checked={isElecSelected}
                        onChange={(e) => {
                          setIsElecSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("elec")) {
                            trades.splice(trades.indexOf("elec"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("elec")
                          ) {
                            trades.push("elec");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />
                      <CheckboxField
                        label="Plumbing"
                        name="subscribe"
                        checked={isPlumSelected}
                        onChange={(e) => {
                          setIsPlumSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("plum")) {
                            trades.splice(trades.indexOf("plum"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("plum")
                          ) {
                            trades.push("plum");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />
                      <Table variation="bordered" size="small">
                        <TableRow>
                          <TableCell border={"none"}></TableCell>
                          <TableCell as="th">Total</TableCell>
                          {isMechSelected && (
                            <TableCell as="th">Mech</TableCell>
                          )}
                          {isElecSelected && (
                            <TableCell as="th">Elec</TableCell>
                          )}
                          {isPlumSelected && (
                            <TableCell as="th">Plum</TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell as="th">Total</TableCell>
                          <TableCell as="th" backgroundColor={"#1d2021"}>
                            <Text
                              fontSize={"130%"}
                              fontWeight={"500"}
                              fontStyle={"italic"}
                              minWidth={"85px"}
                            >
                              {formatNumberUS(
                                getMechTotal() + getElecTotal() + getPlumTotal()
                              )}{" "}
                              $
                            </Text>
                          </TableCell>
                          {isMechSelected && (
                            <TableCell as="th" backgroundColor={"#1d2021"}>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"500"}
                                minWidth={"85px"}
                              >
                                {formatNumberUS(getMechTotal())} $
                              </Text>
                            </TableCell>
                          )}
                          {isElecSelected && (
                            <TableCell as="th" backgroundColor={"#1d2021"}>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"500"}
                                minWidth={"85px"}
                              >
                                {formatNumberUS(getElecTotal())} $
                              </Text>
                            </TableCell>
                          )}
                          {isPlumSelected && (
                            <TableCell as="th" backgroundColor={"#1d2021"}>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"500"}
                                minWidth={"85px"}
                              >
                                {formatNumberUS(getPlumTotal())} $
                              </Text>
                            </TableCell>
                          )}
                        </TableRow>
                        {includeProjectSetUpFee && (
                          <TableRow>
                            <TableCell as="th">Project Setup</TableCell>
                            <TableCell as="th" backgroundColor={"#1d2021"}>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"400"}
                                minWidth={"85px"}
                              >
                                {projectSetUpFee?.total
                                  ? formatNumberUS(projectSetUpFee?.total)
                                  : 0}{" "}
                                $
                              </Text>
                            </TableCell>
                            {isMechSelected && (
                              <TableCell as="th">
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {projectSetUpFee?.mech
                                    ? formatNumberUS(projectSetUpFee?.mech)
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                            )}
                            {isElecSelected && (
                              <TableCell as="th">
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {projectSetUpFee?.elec
                                    ? formatNumberUS(projectSetUpFee?.elec)
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                            )}
                            {isPlumSelected && (
                              <TableCell as="th">
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {projectSetUpFee?.plum
                                    ? formatNumberUS(projectSetUpFee?.plum)
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                        {includeSpaceSetUpFee && (
                          <TableRow>
                            <TableCell as="th">Space Setup</TableCell>
                            <TableCell as="th" backgroundColor={"#1d2021"}>
                              <Text
                                fontSize={"125%"}
                                fontWeight={"400"}
                                minWidth={"85px"}
                              >
                                {formatNumberUS(setUpFee.total[0])} $
                              </Text>
                            </TableCell>
                            {isMechSelected && (
                              <TableCell as="th">
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {setUpFee?.mech[0]
                                    ? formatNumberUS(setUpFee?.mech[0])
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                            )}
                            {isElecSelected && (
                              <TableCell as="th">
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {setUpFee?.elec[0]
                                    ? formatNumberUS(setUpFee?.elec[0])
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                            )}
                            {isPlumSelected && (
                              <TableCell as="th">
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {setUpFee?.plum[0]
                                    ? formatNumberUS(setUpFee?.plum[0])
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                        {Object.keys(calculatedSpace).length > 0 &&
                          calculatedSpace?.areas.map((singleArea, index) => (
                            <TableRow>
                              <TableCell
                                as="th"
                                minWidth={"120px"}
                                maxWidth={"200px"}
                              >
                                {singleArea.areaTitle}:
                              </TableCell>
                              <TableCell backgroundColor={"#1d2021"}>
                                <Text
                                  fontSize={"125%"}
                                  fontWeight={"400"}
                                  minWidth={"85px"}
                                >
                                  {singleArea.priceSheet.total
                                    ? formatNumberUS(
                                        singleArea.priceSheet.total
                                      )
                                    : 0}{" "}
                                  $
                                </Text>
                              </TableCell>
                              {isMechSelected && (
                                <TableCell>
                                  <Text
                                    fontSize={"125%"}
                                    fontWeight={"400"}
                                    minWidth={"85px"}
                                  >
                                    {singleArea.priceSheet.mechTotal
                                      ? formatNumberUS(
                                          singleArea.priceSheet.mechTotal
                                        )
                                      : 0}{" "}
                                    $
                                  </Text>
                                </TableCell>
                              )}
                              {isElecSelected && (
                                <TableCell>
                                  <Text
                                    fontSize={"125%"}
                                    fontWeight={"400"}
                                    minWidth={"85px"}
                                  >
                                    {singleArea.priceSheet.elecTotal
                                      ? formatNumberUS(
                                          singleArea.priceSheet.elecTotal
                                        )
                                      : 0}{" "}
                                    $
                                  </Text>
                                </TableCell>
                              )}
                              {isPlumSelected && (
                                <TableCell>
                                  <Text
                                    fontSize={"125%"}
                                    fontWeight={"400"}
                                    minWidth={"85px"}
                                  >
                                    {singleArea.priceSheet.plumTotal
                                      ? formatNumberUS(
                                          singleArea.priceSheet.plumTotal
                                        )
                                      : 0}{" "}
                                    $
                                  </Text>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                      </Table>
                    </>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SpaceTemplateForm;
