import {
  Button,
  Card,
  Flex,
  Heading,
  Text,
  TextAreaField,
  TextField,
  Link,
  Alert,
  SwitchField,
  View,
  Divider,
} from "@aws-amplify/ui-react";
import { useContext, useEffect, useState } from "react";
import { PzPrimeContext } from "../../Context/ProposalContext";
import {
  MapAndAddressSelector,
  PzTooltip,
  TradeSupervisionSelector,
} from "../index";
import { useNavigate } from "react-router-dom";
import {
  ProjectLocation,
  InstantQuoteSpace,
  Project,
  PriceSheet,
  PricingModel,
  Space,
  SpaceTemplate,
  SpaceCostFlagRelationships,
  CostFlagTemplate,
  BasePrice,
  PriceClass,
  PromoCode,
  Proposal,
} from "../../models";
import axios from "axios";
import { DataStore, Storage } from "aws-amplify";
import ContactInfoModal from "./ContactInfoModal";
import Swal from "sweetalert2";
import awsmobile from "../../aws-exports";
import { proposalTemplateBackup } from "../HtmlEditor/proposalTemplateBackup";

const DynamicQuoteForm = ({ pageView }) => {
  const navig = useNavigate();

  const {
    colorMode,
    userDetails,
    setUserDetails,
    validateEmail,
    setCurrentStage,
    formatNumberUS,
    discipline,
    setProjectArea,
    location,
    distanceMatrixData,
    address,
    setProjectName,
    projectName,
    projectDescription,
    setProjectDescription,
    setProjectId,
    user,
    ProjectCalculator,
    projectPriceSheets,
    setProjectPriceSheets,
    projectId,
    setColorMode,
    setProjectSpaces,
    getProposalData,
    savePdfToS3,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    showPDF
  } = useContext(PzPrimeContext);

  const [changeInInputValues, setChangeInInputValues] = useState(false);
  const [priceRange, setPriceRange] = useState("");

  const [isProjectNameEntered, setIsProjectNameEntered] = useState(true);
  const [isTradeSelected, setIsTradeSelected] = useState(true);
  const [isSquareFootEntered, setIsSquareFootEntered] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isDataEntered, setIsDataEntered] = useState(false);
  const [encodedPDF, setEncodedPDF] = useState("");
  const [isContactVisible, setIsContactVisible] = useState(false);
  const [isAddressVisible, setIsAddressVisible] = useState(false);
  const [spaceTemplates, setSpaceTemplates] = useState();
  const [instantQuoteEntries, setInstantQuoteEntries] = useState({});
  const [previousEmail, setPreviousEmail] = useState("");
  const [previousProjectName, setPreviousProjectName] = useState("");
  const [code, setCode] = useState("");
  const [codeExist, setCodeExist] = useState("");
  const [promoCodeObject, setPromoCodeObject] = useState({});

  setCurrentStage("instantQuote");

  useEffect(() => {
    setPriceRange("");
  }, [changeInInputValues]);

  useEffect(() => {
    getSpaceTemplates();
    setColorMode(pageView === "embed-quote" ? "light" : "dark");
  }, []);

  async function getSpaceTemplates() {
    DataStore.observeQuery(InstantQuoteSpace).subscribe(({ items }) => {
      setSpaceTemplates(items);
    });
  }

  //To Scroll to the end of the component
  const scrollToBottom = () => {
    setTimeout(() => {
      var element = document.getElementById("formEnd");
      element?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 600);
  };

  //Handler function to save project area
  function changeInSpaceTemplateValues(e) {
    if (parseInt(e.target.value) < 0 || e.target.value === "")
      e.target.value = 0;
    instantQuoteEntries[e.target.name] = parseInt(e.target.value);
    setInstantQuoteEntries(instantQuoteEntries);
    setIsSquareFootEntered(true);
    setChangeInInputValues(!changeInInputValues);
  }

  //Handler function to save the user details
  function changeInUserDetails(e) {
    if (e.target.name === "phoneNumber") {
      let phoneNumbers = e.target.value.split("-").join("");
      if (/^[0-9]*$/.test(phoneNumbers)) {
        if (phoneNumbers.length > 6)
          userDetails[e.target.name] =
            phoneNumbers.slice(0, 3) +
            "-" +
            phoneNumbers.slice(3, 6) +
            "-" +
            phoneNumbers.slice(6);
        else if (phoneNumbers.length > 3)
          userDetails[e.target.name] =
            phoneNumbers.slice(0, 3) + "-" + phoneNumbers.slice(3);
        else userDetails[e.target.name] = phoneNumbers;
      } else alert("Enter a Number");
    } else userDetails[e.target.name] = e.target.value;
    setUserDetails(userDetails);
    setChangeInInputValues(!changeInInputValues);
    setIsDataEntered(false);
  }

  //Function to calculate the instant quote and send the post request
  async function runInstantQuote() {
    let [minimumRequirementsFlag, totalSF] = checkMinimumRequirements();

    if (minimumRequirementsFlag === 1) {
      Swal.fire({
        position:
          pageView === "beta" ||
          pageView === "beta-preview" ||
          pageView === "embed-quote"
            ? "bottom"
            : "center",
        imageUrl: require("../../Assets/loader1.gif"),
        imageAlt: "loader",
        imageHeight: "150px",
        imageWidth: "150px",
        timerProgressBar: true,
        html: "<b style='font-size:25px'>Calculating Estimate...</b>",
        didOpen: () => {
          const timer = Swal.getPopup().querySelector("b");
          setTimeout(() => {
            timer.textContent = `Drafting Proposal...`;
          }, 3000);
        },
        showConfirmButton: false,
      });
      const project = await saveProject(totalSF);
      console.log(project);
      const projectSpaces = await saveSpaces(project.id);
      console.log(projectSpaces);
      let projectSpacesList = Object.values(projectSpaces);
      console.log("projectSpacesList-->", projectSpacesList);
      setTimeout(() => {
        DataStore.query(Project, project.id).then(async (res) => {
          console.log(res.priceSheet);
          setProjectPriceSheets(res.priceSheet);
          let [message, response] = await sendInstantQuote(
            res,
            projectSpacesList
          );
          console.log(response.data);
          //Check if message ID exists in the response and throws error if it doesn't exist
          if (message === "success") {
            const proposalData = await getProposalData(res);
            const pdfKey = await savePdfToS3(
              response.data.proposalPdfB64String,
              res.name
            );
            if (
              res.priceSheet.promoDiscounts &&
              res.priceSheet.promoDiscounts.length > 0
            )
              await incrementPromoCodeUses(res.priceSheet.promoDiscounts[0].code);
            if (pdfKey.msg === "success") {
              let [result, proposal] = await saveProposal(
                res,
                proposalData,
                pdfKey.key
              );
              if (result)
                await updateProjectRecord(res, proposal, proposalData);
              setEncodedPDF(response.data.proposalPdfB64String);
              setPriceRange("$ " + `${formatNumberUS(res.priceSheet?.total)}`);
              Swal.close();
              scrollToBottom();
            }else{
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Something went wrong",
                text: "Email was not sent",
                showConfirmButton: true,
              });
            }
          } 
          else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Something went wrong",
              text: "Email was not sent",
              showConfirmButton: true,
            });
          }
        });
      }, 5000);
    } else {
      setIsDataEntered(true);
    }
  }

  async function sendInstantQuote(project, spaces) {
    const proposalData = await getProposalData(project);
    const sendInstantQuoteApi = process.env.REACT_APP_SEND_PROPOSAL_ENDPOINT;
    // process.env.REACT_APP_CHECK_PROPOSAL_TEMPLATE_API_ENDPOINT;
    const primaryEmailAddress = process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;
    console.log(typeof primaryEmailAddress);
    if (proposalData.ccAddresses.hasOwnProperty("value"))
      delete proposalData.ccAddresses["value"];
    let message;
    let response;
    await axios
      .post(sendInstantQuoteApi, {
        // project: project,
        bucketName: awsmobile.aws_user_files_s3_bucket,
        isRealQuote: true,
        primaryEmailAddress: primaryEmailAddress,
        proposalData: proposalData,
        // additionalCCEmail: "",
        isCustomProposal: false,
      })
      .then((res) => {
        response = res;
        if (res.data.emailResponse.messageId) {
          message = "success";
        } else message = "error";
      })
      .catch((e) => {
        response = e;
        message = "catch-error";
        console.log("sendInstantQuote catch", e);
      });

    return [message, response];
  }

  //Function to check all the required fields are entered
  function checkMinimumRequirements() {
    let minimumRequirementsFlag = 1;
    if (projectName.length === 0 && address.length === 0) {
      minimumRequirementsFlag = 0;
      setIsProjectNameEntered(false);
    } else setIsProjectNameEntered(true);
    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (tradeFlag === 0) {
      minimumRequirementsFlag = 0;
      setIsTradeSelected(false);
    } else setIsTradeSelected(true);
    let totalSF = 0;
    for (let spaceTemplateId in instantQuoteEntries) {
      if (instantQuoteEntries[spaceTemplateId] > 0)
        totalSF += instantQuoteEntries[spaceTemplateId];
    }
    if (totalSF > 0) {
      setProjectArea(totalSF);
      setIsSquareFootEntered(true);
    } else {
      minimumRequirementsFlag = 0;
      setIsSquareFootEntered(false);
    }

    if (!validateEmail(userDetails.emailId)) {
      minimumRequirementsFlag = 0;
      setIsEmailValid(false);
    } else setIsEmailValid(true);

    return [minimumRequirementsFlag, totalSF];
  }

  //Function to save or update the Project
  async function saveProject(totalSF) {
    let response;
    const instantQuoteName =
      projectName.length === 0 ? address.split(",")[0] : projectName;

    const tradeConversion = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
    };

    let trades = [];
    for (const trade in discipline) {
      if (discipline[trade] === 1) trades.push(tradeConversion[trade]);
    }

    //
    let projectBaseFees = {};
    let baseFeesRecords = await DataStore.query(BasePrice, (basePriceType) =>
      basePriceType.priceClass.eq(PriceClass.DISCIPLINE)
    );
    const baseFeesTradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
    };
    for (const departmentRecord of baseFeesRecords) {
      projectBaseFees[departmentRecord.name] = [
        departmentRecord["setUpFee"],
        departmentRecord["costPerSF"],
      ];
    }

    // Check if Project already exists
    if (
      previousProjectName === instantQuoteName &&
      previousEmail === userDetails.emailId
    ) {
      // Project exists, update project
      const currentProject = await DataStore.query(Project, projectId);
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.name = instantQuoteName;
          projectCopy.projectArea = totalSF;
          projectCopy.constructionType = "GROUND_UP";
          projectCopy.tradeSupervisions = trades;
          projectCopy.markets = [];
          projectCopy.spaceDict = {};
          projectCopy.location = new ProjectLocation({
            latitude: location.lat,
            longitude: location.lng,
            address: address,
            driveDistance: distanceMatrixData.distance,
            driveDuration: distanceMatrixData.time,
          });
          projectCopy.packageLevel = "COLLABORATOR";
          projectCopy.promoCodes =
            Object.keys(promoCodeObject).length > 0 ? [promoCodeObject] : [];
          projectCopy.priceSheet = new PriceSheet({
            pricingModel: PricingModel.PROJECT_BASE_FEES,
            mech: projectBaseFees["mech"],
            elec: projectBaseFees["elec"],
            plum: projectBaseFees["plum"],
          });
        })
      )
        .then((res) => {
          response = res;
        })
        .catch((err) => {
          response = "error";
          console.log(err);
        });
    }

    // no existing project, create new project
    else {
      await DataStore.save(
        new Project({
          name: instantQuoteName,
          userEmail: userDetails.emailId ? userDetails.emailId : null,
          projectArea: totalSF,
          constructionType: "GROUND_UP",
          numberOfBuildings: 1,
          tradeSupervisions: trades,
          markets: [],
          location: new ProjectLocation({
            latitude: location.lat,
            longitude: location.lng,
            address: address,
            driveDistance: distanceMatrixData.distance,
            driveDuration: distanceMatrixData.time,
          }),
          packageLevel: "COLLABORATOR",
          editors: user ? [user] : [],
          priceSheet: new PriceSheet({
            pricingModel: PricingModel.PROJECT_BASE_FEES,
            mech: projectBaseFees["mech"],
            elec: projectBaseFees["elec"],
            plum: projectBaseFees["plum"],
          }),
          projectDescription: projectDescription,
          userName: userDetails.name,
          userPhoneNumber: userDetails.phoneNumber,
          isInstantQuote: true,
          promoCodes:
            Object.keys(promoCodeObject).length > 0 ? [promoCodeObject] : [],
          spaceDict: {},
        })
      )
        .then((res) => {
          setProjectId(res.id);
          setPreviousEmail(userDetails.emailId);
          setPreviousProjectName(instantQuoteName);
          if (!projectName) setProjectName(address.split(",")[0]);
          response = res;
        })
        .catch((err) => {
          response = "error";
          console.log(err);
        });
    }
    return response;
  }

  // Funtion to save the spaces to the project record
  async function saveSpaces(proId) {
    let projectSpaces = {};
    // For each SF entry, creating the space record
    for (let spaceTemplateId in instantQuoteEntries) {
      // Checking if valid input
      if (instantQuoteEntries[spaceTemplateId] > 0) {
        let space = await DataStore.query(SpaceTemplate, spaceTemplateId);
        let newSpace = {
          customName: space.templateName,
          className: space.templateName,
          templateID: space.id,
          areas: [],
          auxInputs: [],
          costFlags: [],
          priceSheet: space.priceSheet,
        };
        for (const input of space.areas) {
          let spaceInputField = {
            areaTitle: input.areaTitle,
            instantQuoteWeight: input.instantQuoteWeight,
            area: parseFloat(
              (instantQuoteEntries[spaceTemplateId] *
                input.instantQuoteWeight) /
                100
            ),
            priceSheet: input.priceSheet,
          };
          newSpace["areas"].push(spaceInputField);
        }
        for (const input of space.auxInputs) {
          let spaceAuxInputField = {
            inputTitle: input.inputTitle,
            dataType: input.dataType,
          };
          if (input.dataType === "INTEGER") spaceAuxInputField["intValue"] = 0;
          else spaceAuxInputField["checkedValue"] = false;
          newSpace["auxInputs"].push(spaceAuxInputField);
        }
        const spaceFlags = await DataStore.query(
          SpaceCostFlagRelationships,
          (relation) => relation.spaceTemplateId.eq(space.id)
        );
        for (const input of spaceFlags) {
          const flag = await DataStore.query(
            CostFlagTemplate,
            input.costFlagTemplateId
          );
          let spaceFlags = {
            featureTitle: flag.featureTitle,
            checkedValue: false,
            alertLevel: flag.alertLevel,
            alertTitle: flag.alertTitle,
            alertBody: flag.alertBody,
          };
          newSpace["costFlags"].push(spaceFlags);
        }

        projectSpaces[newSpace.customName] = newSpace;
      }
    }
    const currentProject = await DataStore.query(Project, proId);
    await DataStore.save(
      Project.copyOf(currentProject, (projectCopy) => {
        projectCopy.spaceDict = projectSpaces;
      })
    ).then((res) => {
      setTimeout(() => {
        ProjectCalculator(
          res.id,
          setProjectPriceSheets,
          setProjectSpaces,
          user
        );
      }, 3000);
    });
    return projectSpaces;
  }

  setCurrentStage("instantQuote");

  const Css = `
  .iconHover:hover{
    background:#7dd6e8;
    color:#000;
  }
  .textArea-styles{
    overflow-wrap: break-word;
    }
  .iQIndex{
      // z-index:99;
  }
  .invisibleBlockWix:after {
    content: "";
    display: block;
    height: 120px;
    width: 100%;
  }
    `;

  function springboardProject() {
    // const domainName = process.env.REACT_APP_HOSTING_URL;
    let originUrl = window.location.origin;
    console.log(originUrl);
    window.open(
      `${originUrl}/?springBoardProject=${true}&projectId=${projectId}`
    );
  }

  async function validatePromoCode() {
    const promoCodes = await DataStore.query(PromoCode, (promoCode) =>
      promoCode.code.eq(code)
    );
    if (
      promoCodes.length > 0 &&
      !promoCodes[0].disablePromoCode &&
      (promoCodes[0].requirements.requireSignin === false || user !== "")
    ) {
      if (
        !promoCodes[0].applyExpirationDate ||
        new Date(promoCodes[0].expirationDate) - new Date() > 0
      ) {
        setPromoCodeObject({
          disablePromoCode: promoCodes[0].disablePromoCode,
          expirationDate: promoCodes[0].expirationDate,
          applyExpirationDate: promoCodes[0].applyExpirationDate,
          code: promoCodes[0].code,
          requirements: {
            requireSignin: promoCodes[0].requirements.requireSignin,
            minimumSubtotal: promoCodes[0].requirements.minimumSubtotal,
            minimumArea: promoCodes[0].requirements.minimumArea,
          },
          promoClass: promoCodes[0].promoClass,
          discount: promoCodes[0].discount,
        });
        setCodeExist("true");
      } else {
        setPromoCodeObject({});
        setCodeExist("false");
      }
    } else {
      setPromoCodeObject({});
      setCodeExist("false");
    }
  }

  return (
    <Flex
      justifyContent={"center"}
      // className="iQIndex"
      // overflow={"auto"}
      padding={"0"}
      gap={"0"}
    >
      <style>{Css}</style>
      <Card
        display={"flex"}
        direction={"column"}
        alignItems="flex-start"
        justifyContent={"flex-start"}
        gap="20px"
        maxWidth={{
          small: pageView !== "embed-quote" ? "95%" : "100%",
          medium: pageView !== "embed-quote" ? "80%" : "100%",
          large: pageView !== "embed-quote" ? "65%" : "100%",
          xl: pageView !== "embed-quote" ? "65%" : "100%",
        }}
        width={"100%"}
        minWidth={"350px"}
        // height={"100vh"}
        padding={pageView !== "embed-quote" ? "20px" : "0"}
      >
        <Flex
          width={"100%"}
          id="formEnd"
          paddingBottom={"1px"}
          direction={"column"}
        >
          <Flex
            direction={"column"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            borderRadius="10px"
            padding={"15px"}
            width={"100%"}
          >
            <Card
              width={"100%"}
              borderRadius="10px"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
              padding={"10px"}
            >
              <Text fontSize={"25px"}>Project Info</Text>
              <TradeSupervisionSelector
                setChangeInInputValues={setChangeInInputValues}
                changeInInputValues={changeInInputValues}
                isInstantQuoteForm={true}
                isTradeSelected={isTradeSelected}
                setIsTradeSelected={setIsTradeSelected}
                setIsDataEntered={setIsDataEntered}
              />
            </Card>
            <Card
              direction={"column"}
              width={"100%"}
              padding={"15px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text fontSize={"22px"} marginBottom={"10px"}>
                Project Area (SF)
              </Text>
              {!isSquareFootEntered && (
                <Text
                  fontSize={"16px"}
                  marginLeft={"5px"}
                  marginBottom={"0px"}
                  color={colorMode === "dark" ? "#f5bcbc" : "#660000"}
                >
                  *Enter Square Footage for one or more project categories
                </Text>
              )}

              <Flex width="100%" wrap={"wrap"}>
                {spaceTemplates &&
                  spaceTemplates.map((spaceTemplate, index) => (
                    <TextField
                      key={index}
                      maxWidth={"400px"}
                      width={"100%"}
                      fontSize={"20px"}
                      padding={"5px"}
                      onFocus={(e) => e.target.select()}
                      type="number"
                      label={spaceTemplate.spaceTemplateName}
                      name={spaceTemplate.instantQuoteSpaceSpaceTemplateId}
                      value={
                        instantQuoteEntries[
                          spaceTemplate.instantQuoteSpaceSpaceTemplateId
                        ]
                          ? instantQuoteEntries[
                              spaceTemplate.instantQuoteSpaceSpaceTemplateId
                            ]
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => changeInSpaceTemplateValues(e)}
                      placeholder="0 SF"
                      hasError={!isSquareFootEntered}
                    />
                  ))}
              </Flex>
            </Card>
            <Card
              direction={"column"}
              width={"100%"}
              padding={"15px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text fontSize={"22px"} marginBottom={"10px"}>
                Optional - Project Description
              </Text>
              <TextAreaField
                resize="vertical"
                placeholder="Tell us more about your project"
                rows={3}
                className="textArea-styles"
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
              />
            </Card>
            <Card
              display={"flex"}
              gap="15px"
              direction={"column"}
              width={"100%"}
              padding={"15px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text fontSize={"22px"} marginBottom={"10px"}>
                Contact Information
              </Text>
              <TextField
                placeholder="Enter your Name"
                label="Name"
                name="name"
                value={userDetails.name}
                onChange={(e) => changeInUserDetails(e)}
              />
              <TextField
                placeholder="Enter your Email Address"
                label="Email"
                name="emailId"
                value={userDetails.emailId}
                hasError={!isEmailValid && !validateEmail(userDetails.emailId)}
                errorMessage="Enter valid Email Id"
                onChange={(e) => {
                  changeInUserDetails(e);
                  setIsEmailValid(validateEmail(userDetails.emailId));
                }}
              />
              <TextField
                placeholder="Enter your Phone Number"
                label="Phone Number (optional)"
                name="phoneNumber"
                value={userDetails.phoneNumber}
                onChange={(e) => changeInUserDetails(e)}
              />
            </Card>

            <Card
              direction={"column"}
              width={"100%"}
              padding={"0 5px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Flex direction={"column"} gap={"0"} alignItems={"center"}>
                <TextField
                  width={"100%"}
                  fontSize={"22px"}
                  padding={"10px 15px"}
                  label={"Project Name"}
                  placeholder="Enter a project name and/or valid address"
                  value={projectName}
                  hasError={!isProjectNameEntered}
                  errorMessage="*Enter a project name and/or valid address"
                  onChange={(e) => {
                    setProjectName(e.target.value);
                    setChangeInInputValues(!changeInInputValues);
                    setIsProjectNameEntered(true);
                    setIsDataEntered(false);
                  }}
                />
                <Flex
                  justifyContent={"flex-start"}
                  width={"100%"}
                  padding={"5px 8px"}
                >
                  <SwitchField
                    isDisabled={false}
                    label="Add Project Address?"
                    labelPosition="start"
                    onClick={(e) => {
                      setIsAddressVisible(e.target.checked);
                    }}
                  />
                </Flex>

                {isAddressVisible && (
                  <Flex
                    direction={"column"}
                    width={"100%"}
                    borderRadius="10px"
                    // padding={"15px"}
                    gap={"20px"}
                  >
                    <MapAndAddressSelector
                      isInstantQuoteForm={true}
                      setChangeInInputValues={setChangeInInputValues}
                      changeInInputValues={changeInInputValues}
                      setIsProjectNameEntered={setIsProjectNameEntered}
                      isProjectNameEntered={isProjectNameEntered}
                      setIsDataEntered={setIsDataEntered}
                    />
                  </Flex>
                )}
              </Flex>
            </Card>
            <Card
              direction={"column"}
              width={"100%"}
              padding={"15px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text fontSize={"22px"} marginBottom={"10px"}>
                Promo Code
              </Text>
              <Flex alignItems={"flex-end"} gap={"20px"}>
                <TextField
                  width={"100%"}
                  maxWidth={"400px"}
                  fontSize={"22px"}
                  placeholder="Enter promo code"
                  errorMessage="*Enter a project name and/or valid address"
                  value={code}
                  onChange={(e) => {
                    setCodeExist("");
                    setCode(e.target.value);
                    setChangeInInputValues(!changeInInputValues);
                  }}
                />
                <Button
                  backgroundColor={"#40AABF"}
                  color={"#fff"}
                  onClick={() => {
                    validatePromoCode();
                  }}
                >
                  Add Code
                </Button>
              </Flex>
              {codeExist === "true" && (
                <Alert
                  variation="success"
                  width={"fit-content"}
                  marginTop={"15px"}
                  borderRadius={"5px"}
                >
                  Promo code added to project!
                </Alert>
              )}
              {codeExist === "false" && (
                <Alert
                  variation="error"
                  width={"fit-content"}
                  marginTop={"15px"}
                  borderRadius={"5px"}
                >
                  Promo code not found
                </Alert>
              )}
            </Card>
            <Button
              width="100%"
              backgroundColor={"#40AABF"}
              color={"#fff"}
              margin={"30px 0"}
              onClick={() => {
                runInstantQuote();
                scrollToBottom();
              }}
            >
              Estimate MEP Costs
            </Button>

            {isDataEntered && (
              <Alert variation="warning" width={"100%"} id="warn">
                Please fill in all required fields to complete your estimate
              </Alert>
            )}
            {priceRange && (
              <Card
                borderRadius={"20px"}
                backgroundColor={colorMode === "dark" && "#2e3436"}
                display={"flex"}
                direction={"column"}
                padding={"20px"}
                border={"1px solid #c5c5c5"}
                alignItems="center"
                justifyContent={"flex-start"}
                gap="5px"
                width={"100%"}
                id="detailCard"
              >
                {Object.keys(promoCodeObject).length > 0 ? (
                  projectPriceSheets?.promoDiscounts.length > 0 &&
                  projectPriceSheets?.promoDiscounts[0].discount > 0 ? (
                    <>
                      <Heading level={5}>
                        Initial Estimate: $
                        {formatNumberUS(projectPriceSheets?.initialSubtotal)}
                      </Heading>
                      <Divider
                        orientation="horizontal"
                        width={"40%"}
                        margin={"15px 0"}
                      />
                      <Heading level={4}>
                        Promo Code Savings (
                        {projectPriceSheets?.promoDiscounts[0].code}
                        ):{" "}
                        <span style={{ color: "#26C281" }}>
                          -$
                          {formatNumberUS(
                            projectPriceSheets?.promoDiscounts[0].discount
                          )}
                        </span>
                      </Heading>
                      <Divider
                        orientation="horizontal"
                        width={"40%"}
                        margin={"15px 0"}
                      />
                      <Heading level={3}>Final Cost (Incl. Tax):</Heading>
                    </>
                  ) : (
                    <>
                      <Alert
                        variation="error"
                        hasIcon={false}
                        textAlign={"center"}
                        fontStyle={"italic"}
                        margin={"10px 0"}
                      >
                        Project details ineligible for promo code "
                        {promoCodeObject?.code}"
                      </Alert>

                      <Heading level={3}>Project Total (Incl. Tax):</Heading>
                    </>
                  )
                ) : (
                  <>
                    <Heading level={3}>Project Total (Incl. Tax):</Heading>
                  </>
                )}
                <Heading level={3}>
                  $ {formatNumberUS(projectPriceSheets?.total)}
                </Heading>

                <Flex direction={"column"} gap={"0"} marginTop={"15px"}>
                  <Alert
                    variation="success"
                    hasIcon={false}
                    width={"100%"}
                    textAlign={"center"}
                  >
                    This estimate and a sample proposal have been emailed to{" "}
                    {userDetails.emailId} for review!
                  </Alert>
                  <Flex
                    direction={{
                      base: "column",
                      small: "column",
                      medium: "column",
                      large: "row",
                      xl: "row",
                    }}
                    gap={{
                      base: "0",
                      small: "0",
                      medium: "0",
                      large: "10px",
                      xl: "10px",
                    }}
                  >
                    <Button
                      width="100%"
                      minWidth={"300px"}
                      marginTop={"20px"}
                      marginBottom={"30px"}
                      onClick={() => {
                        setIsContactVisible(true);
                      }}
                    >
                      Contact PermitZIP
                    </Button>
                    <Button
                      width="100%"
                      minWidth={"300px"}
                      marginTop={"20px"}
                      marginBottom={"30px"}
                      onClick={() => showPDF(encodedPDF)}
                    >
                      <Link style={{ color: "inherit" }}>
                        Draft/View Sample Contract
                      </Link>
                    </Button>
                  </Flex>

                  {/* TODO: beta, beta-preview and embed-quote page views which disables the Get Detailed Estimate buttom */}
                  {/* {pageView === "beta" ||
                pageView === "beta-preview" ||
                pageView === "embed-quote" ? ( */}
                  <PzTooltip
                    children={
                      <Button
                        width="100%"
                        border={"1px solid #c5c5c5"}
                        marginBottom={"25px"}
                        // onClick={() => {
                        //   goToWebsite();
                        // }}
                        isDisabled
                      >
                        Get Detailed Estimate
                      </Button>
                    }
                    text={"Coming soon!"}
                  />
                  {/* ) : (
                  <Button
                    width="100%"
                    border={"1px solid #c5c5c5"}
                    marginBottom={"25px"}
                    onClick={() => {
                      springboardProject();
                    }}
                  >
                    Get Detailed Estimate`
                  </Button>
                )} */}
                </Flex>
              </Card>
            )}
          </Flex>
        </Flex>
      </Card>
      <View className="invisibleBlockWix"></View>

      <ContactInfoModal
        props={{ projectName, setIsContactVisible, isContactVisible }}
        modalPosition={
          pageView === "beta" ||
          pageView === "beta-preview" ||
          pageView === "embed-quote"
            ? "bottom"
            : "center"
        }
      />
    </Flex>
  );
};

export default DynamicQuoteForm;
