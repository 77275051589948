import {
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Icon,
  Card,
  View,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import SpaceSelectorForm from "./SpaceSelectorForm";
import SpaceSelector from "./SpaceSelector";
import { DataStore } from "aws-amplify";
import { Space, CostFlagTemplate, Project } from "../models";
import { EmailEstimateModal, ModalComponent } from ".";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const SpaceSelectorOverview = (props) => {
  const navig = useNavigate();

  const {
    setSpaceId,
    changeInSpace,
    setProjectPriceSheets,
    ProjectCalculator,
    projectId,
    setSpaceFlags,
    addCommaToPrice,
    formatNumberUS,
    spaceImages,
    isRequirementMet,
    currentStage,
    projectArea,
    configuredSpaces,
    changeInInput,
    setChangeInInput,
    projectSpaces,
    setProjectSpaces,
    user
  } = useContext(PzPrimeContext);

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [spaces, setSpaces] = useState([]);
  const [costFlags, setCostFlags] = useState([]);

  async function getSpaces() {
    DataStore.observeQuery(Project, (project) =>
      project.id.eq(projectId)
    ).subscribe(({ items }) => {
      console.log(items);
      setProjectSpaces(items[0].spaceDict)
    });
  }

  useEffect(() => {
    if (currentStage === "spaceSelector") getSpaces();
  }, []);

  useEffect(() => {
    DataStore.observeQuery(CostFlagTemplate).subscribe(({ items }) => {
      setCostFlags(items);
    });
  }, []);

  function totalSqFt(spaceName) {
        let totalSpace = 0;
    let space = projectSpaces[spaceName]
    for (let SF of space.areas) {
      totalSpace += parseInt(SF.area);
    }
    return totalSpace;
  }

  const customCss = `
      .cursor{
          cursor:pointer;
      } 
      .pointerEvents{
        pointer-events: none; 
        user-select:none;
      }
      .outline{
        outline: 1px solid rgba(191, 191, 191, 0.80);
      }
      .iconHover:hover{
        background:lightblue;
      }
      .hover:hover{
        -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        transition: 0.1s ease-in;
      }

      .addSpaceBox {
        background-image: repeating-linear-gradient(-47deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(43deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(133deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(223deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px);
        background-size: 2px calc(100% + 41.06px), calc(100% + 41.06px) 2px, 2px calc(100% + 41.06px) , calc(100% + 41.06px) 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        animation: borderAnimation 2s infinite linear;
        border-radius:10px;
        }
        
        // @keyframes borderAnimation {
        // from { background-position: 0 0, -41.06px 0, 100% -41.06px, 0 100%; }
        // to { background-position: 0 -41.06px, 0 0, 100% 0, -41.06px 100%; }
        // }
      .layerBottom{
        z-index:0;
      }

      `;

  async function updateSpace(space, customName, isDuplicate) {

    if (isDuplicate) {
      let newSpace = JSON.parse(JSON.stringify(space));
      newSpace.customName = customName;
      let tempSpaces = {}
      tempSpaces = JSON.parse(JSON.stringify(projectSpaces));
      tempSpaces[customName] = newSpace
      setProjectSpaces(tempSpaces)
      const currentProject = await DataStore.query(Project, projectId)
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy)=>{
          projectCopy.spaceDict = tempSpaces
        })
      ).then((res)=>{
        setProjectSpaces(JSON.parse(JSON.stringify(res.spaceDict)))
        props.setTempSpaceInputs(JSON.parse(JSON.stringify(res.spaceDict[customName])));
        setIsEdit(true);
        props.setSelectedSpace(space.customName);
      }).catch((err) => console.log(err));
      
    } else {
      props.setTempSpaceInputs(JSON.parse(JSON.stringify(space)));
      setIsEdit(true);
      props.setSelectedSpace(space.customName);
    }
  }

  async function editSpace(space) {
    setSpaceId(space.id);
    props.setMode("edit");
    updateSpace(space, space.customName, false);
  }

  async function deleteSpace(space) {
    // const currentSpace = await DataStore.query(Space, space.id);
    // await DataStore.delete(currentSpace);
    // ProjectCalculator(projectId, setProjectPriceSheets);
    if(Object.keys(projectSpaces).length === 1)
      await DataStore.delete(Space, (space)=> space.projectSpacesId.eq(projectId))
    let tempSpaceDict = {}
    if(projectSpaces) {
      tempSpaceDict = JSON.parse(JSON.stringify(projectSpaces))
      delete tempSpaceDict[space.customName]
    }
    setProjectSpaces(tempSpaceDict)
    const currentProject = await DataStore.query(Project, projectId)
    await DataStore.save(
      Project.copyOf(currentProject, (projectCopy)=>{
        projectCopy.spaceDict = tempSpaceDict
      })
    ).then((res)=>{
      setTimeout(()=>{ProjectCalculator(projectId, setProjectPriceSheets, setProjectSpaces, user);},2000)
    })
  }

  function createDuplicate(space) {
    props.setMode("edit");
    let name;
    let spaceFilter = Object.keys(projectSpaces).map((spaceInput) => {
      if (projectSpaces[spaceInput]['templateID'] === space.templateID) return projectSpaces[spaceInput]['customName'];
    });
    let i = 1;
    let nameFlag = false;
    while (!nameFlag) {
      let tempName;
      if (i === 1) tempName = space.className;
      else tempName = space.className + "-" + i;
      if (!spaceFilter.includes(tempName)) {
        name = tempName;
        nameFlag = true;
      }
      i++;
    }
    updateSpace(space, name, true);
  }

  async function nextStep() {
    let tempSpaceFlags = [];
    for (let flag of costFlags) {
      for (let space of props.spaces) {
        var isSetFlag = false;
        for (let spaceFlag of space.costFlags) {
          if (
            spaceFlag.featureTitle === flag.featureTitle &&
            spaceFlag.checkedValue
          ) {
            tempSpaceFlags.push(flag.featureTitle);
            isSetFlag = true;
            break;
          }
        }
        if (isSetFlag) break;
      }
    }
    setSpaceFlags(tempSpaceFlags);
    const projectFlags = await DataStore.query(CostFlagTemplate);
    const spaceProjectFlag = await DataStore.query(Space, (space) =>
      space.and((space) => [
        space.projectSpacesId.eq(projectId),
        space.className.eq("Project Cost Flags"),
      ])
    );
    let globalCostFlags = [];
    for (let costFLag of projectFlags) {
      let tempFlag = spaceProjectFlag[0].costFlags.filter(
        (flag) => flag.featureTitle === costFLag.featureTitle
      );
      let newFlag;
      if (tempFlag.length > 0)
        newFlag = {
          featureTitle: costFLag.featureTitle,
          checkedValue: tempFlag[0].checkedValue,
          alertLevel: costFLag.alertLevel,
          alertTitle: costFLag.alertTitle,
          alertBody: costFLag.alertBody,
        };
      else
        newFlag = {
          featureTitle: costFLag.featureTitle,
          checkedValue: false,
          alertLevel: costFLag.alertLevel,
          alertTitle: costFLag.alertTitle,
          alertBody: costFLag.alertBody,
        };
      globalCostFlags.push(newFlag);
    }
    await DataStore.save(
      Space.copyOf(spaceProjectFlag[0], (spaceCopy) => {
        spaceCopy.costFlags = globalCostFlags;
      })
    );
    navig("/package-selector");
  }

  return (
    <>
      <style>{customCss}</style>
      {!isEdit ? (
        props.addNewSpace ? (
          <SpaceSelector
            addNewSpace={props.addNewSpace}
            setAddNewSpace={props.setAddNewSpace}
          />
        ) : (
          <>
            <Flex wrap={"wrap"} gap={"10px"} padding={"30px"}>
              <Flex
                direction={{
                  medium: "column",
                  large: "column",
                  xl: "row",
                  xxl: "row",
                }}
                justifyContent={"flex-start"}
                alignItems={"center"}
                className="addSpaceBox cursor hover"
                padding={"10px"}
                margin={"15px"}
                width={"100%"}
                maxWidth={"1600px"}
                minHeight={"250px"}
                minWidth={"350px"}
                position={"relative"}
                onClick={() => props.setAddNewSpace(true)}
                paddingLeft={{ medium: "0px", large: "0px", xl: "150px" }}
              >
                <Image
                  className="cursor pointerEvents"
                  alt="PermitZip logo"
                  src={require("../Assets/addBtn.png")}
                  height="125px"
                  aspectRatio={"1/1"}
                />
                <Heading level={2} paddingLeft={"50px"} color={"#aeb3b7"}>
                  Add Another Space
                </Heading>
              </Flex>
              {Object.keys(projectSpaces).map((space, index) => {
                if (projectSpaces[space]['className'] !== "Project Cost Flags")
                  return (
                    <Card
                      key={index}
                      margin={"10px"}
                      display={"flex"}
                      direction={{
                        base: "column",
                        small: "column",
                        medium: "column",
                        large: "column",
                        xl: "row",
                        xxl: "row",
                      }}
                      borderRadius="10px"
                      border="1px solid #A4A4A4"
                      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                      minHeight={"250px"}
                      justifyContent="space-between"
                      alignItems="stretch"
                      maxWidth={"1600px"}
                      width={{ medium: "auto", large: "auto", xl: "100%" }}
                    >
                      <Flex
                        direction="column"
                        borderRadius="8px"
                        backgroundColor="buttonface"
                        minWidth={"250px"}
                        width={"100%"}
                        maxWidth={{
                          medium: "100%",
                          large: "100%",
                          xl: "300px",
                          xxl: "380px",
                        }}
                        padding={"15px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        position={"relative"}
                      >
                        <View
                          width={"160px"}
                          height={"160px"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          direction={"column"}
                          borderRadius={"50%"}
                          overflow={"hidden"}
                        >
                          <Image
                            alt="PermitZip logo"
                            src={
                              spaceImages[projectSpaces[space]['templateID']]
                                ? spaceImages[projectSpaces[space]['templateID']]?.imageURL
                                : require("../Assets/defaultSpaceImage.jpg")
                            }
                            height="100%"
                            width="100%"
                            objectFit={"cover"}
                            className="pointerEvents"
                          />
                        </View>

                        <Flex direction={"column"} gap={"0"}>
                          <Text
                            className={"cursor pointerEvents"}
                            textAlign={"center"}
                            fontSize="25px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="130%"
                          >
                            {projectSpaces[space]['customName']}
                          </Text>
                          <Text
                            className={"cursor pointerEvents"}
                            margin={"10px"}
                            textAlign={"center"}
                            fontSize="15px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="130%"
                          >
                            {projectSpaces[space]['className']} -{" "}
                            {formatNumberUS(totalSqFt(projectSpaces[space]['customName']), 0)} SF
                          </Text>
                          <Text
                            className={"cursor pointerEvents"}
                            textAlign={"center"}
                            fontSize="20px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="130%"
                          >
                            ${" "}
                            {projectSpaces[space]['priceSheet']?.total
                              ? formatNumberUS(projectSpaces[space]['priceSheet'].total)
                              : 0}
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex
                        direction={"column"}
                        padding={"10px"}
                        borderRadius={"10px"}
                        width={"max-content"}
                        minWidth={"400px"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <Heading>Details</Heading>
                        {projectSpaces[space]['areas'].map((spaceInput, index) => (
                          <Flex
                            key={index}
                            padding={"8px"}
                            borderRadius={"10px"}
                            width="100%"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            backgroundColor={"ButtonFace"}
                          >
                            <Text>{spaceInput.areaTitle}</Text>
                            <Text>{formatNumberUS(spaceInput.area, 0)} SF</Text>
                          </Flex>
                        ))}
                        {projectSpaces[space]['auxInputs'].map((spaceInput, index) => (
                          <Flex
                            key={index}
                            padding={"8px"}
                            borderRadius={"10px"}
                            width={"100%"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            backgroundColor={"ButtonFace"}
                          >
                            <Text>{spaceInput.inputTitle}</Text>
                            <Text>
                              {spaceInput.dataType === "INTEGER"
                                ? spaceInput.intValue
                                : spaceInput.checkedValue
                                ? "Yes"
                                : "No"}
                            </Text>
                          </Flex>
                        ))}
                      </Flex>
                      <Flex
                        direction={"column"}
                        padding={"10px"}
                        borderRadius={"10px"}
                        minWidth={"200px"}
                      >
                        <Heading textAlign={"center"}>Actions</Heading>
                        <Flex
                          wrap={"wrap"}
                          justifyContent="center"
                          alignItems={"center"}
                          direction={"column"}
                        >
                          <Button
                            variation="primary"
                            borderRadius={"25px"}
                            onClick={() => editSpace(projectSpaces[space])}
                            minWidth={"150px"}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Image
                                src={require("../Assets/edit.png")}
                                padding={"3px"}
                                height={"32px"}
                                borderRadius={"20px"}
                              />
                              <Text color={"#f4f4f4"}>Edit</Text>
                            </Flex>
                          </Button>
                          <Button
                            borderRadius={"25px"}
                            variation="primary"
                            minWidth={"150px"}
                            onClick={() => createDuplicate(projectSpaces[space])}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={"34px"}
                                borderRadius={"20px"}
                                padding={"3px"}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect width="24" height="24" />
                                  <path
                                    d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
                                    stroke="#000"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <rect
                                    x="-285.5"
                                    y="-382.5"
                                    width="524"
                                    height="817"
                                    rx="4.5"
                                    strokeDasharray="10 5"
                                  />
                                </svg>
                              </Icon>
                              <Text color={"#f4f4f4"}>Duplicate</Text>
                            </Flex>
                          </Button>
                          <Button
                            variation="destructive"
                            borderRadius={"25px"}
                            minWidth={"150px"}
                            onClick={() => deleteSpace(projectSpaces[space])}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Image
                                src={require("../Assets/delete.png")}
                                height={"32px"}
                                padding={"3px"}
                                borderRadius={"20px"}
                              />
                              <Text color={"#f4f4f4"}>Delete</Text>
                            </Flex>
                          </Button>
                        </Flex>
                      </Flex>
                    </Card>
                  );
              })}
            </Flex>

            <Button
              position={"fixed"}
              bottom={"25px"}
              right={"400px"}
              width={"200px"}
              margin={"10px 30px"}
              backgroundColor={"#40AABF"}
              color={"#fff"}
              onClick={() => {
                setChangeInInput(!changeInInput);
                if (!isRequirementMet) {
                  Swal.fire({
                    title: "Missing Project Area",
                    html: `
                    <p>
                    The configured project area (${configuredSpaces} SF) does not match the declared project area (${projectArea} SF)
                    </p>
                    
                    <p>
                    <br>
                    If you continue, <b>only the configured area will be represented and covered by your proposal.</b>
                    </p>
                    `,
                    showDenyButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Go Back `,
                    denyButtonText: "Ignore and Continue",
                    confirmButtonColor: "#40aabf",
                    denyButtonColor: "#40aabf",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.close();
                    } else if (result.isDenied) {
                      setOpenModal(true);
                    }
                  });
                } else {
                  setOpenModal(true);
                }
              }}
            >
              Email Estimate
            </Button>
            <ModalComponent
              showModal={openModal}
              onClose={() => setOpenModal(false)}
              children={
                <EmailEstimateModal onClose={() => setOpenModal(false)} />
              }
            />
          </>
        )
      ) : (
        <SpaceSelectorForm
          selectedSpace={props.selectedSpace}
          setSelectedSpace={props.setSelectedSpace}
          tempSpaceInputs={props.tempSpaceInputs}
          setTempSpaceInputs={props.setTempSpaceInputs}
          mode={props.mode}
          setAddNewSpace={props.setAddNewSpace}
          spaces={props.spaces}
        />
      )}
    </>
  );
};

export default SpaceSelectorOverview;
